import React from 'react';
import { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";

const Pricing = () => {

    useEffect(() => {            
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }    
        
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
              });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }
         });

         var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

         fadeElementsLoad.forEach(function(loadelement) {
     
             loadelement.style.opacity = 1;
             loadelement.style.visibility = 'visible';
             loadelement.style.webkitTransform = 'translateY(0px)';
             loadelement.style.transform = 'translateY(0px)';
         });
         

        window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
                fadeElements.forEach(function(element) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(0px)';
                    element.style.transform = 'translateY(0px)';
                });
            }
        });

        var afterCard               = document.getElementById('after-card');
        var beforeCard               = document.getElementById('before-card');

        afterCard.style.display = 'none';

        var switchButton 			= document.querySelector('.switch-button');
        var switchBtnRight 			= document.querySelector('.switch-button-case.right');
        var switchBtnLeft 			= document.querySelector('.switch-button-case.left');
        var activeSwitch 			= document.querySelector('.active');

        function switchLeft(){
            switchBtnRight.classList.remove('active-case');
            switchBtnLeft.classList.add('active-case');
            activeSwitch.style.left 						= '0%';
            beforeCard.style.display = 'block';
            afterCard.style.display = 'none';
        }

        function switchRight(){
            switchBtnRight.classList.add('active-case');
            switchBtnLeft.classList.remove('active-case');
            activeSwitch.style.left 						= '50%';
            beforeCard.style.display = 'none';
            afterCard.style.display = 'block';

        }

        switchBtnLeft.addEventListener('click', function(){
            switchLeft();
        }, false);

        switchBtnRight.addEventListener('click', function(){
            switchRight();
        }, false);

        var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

         fadeElementsLoad.forEach(function(loadelement) {
     
             loadelement.style.opacity = 1;
             loadelement.style.visibility = 'visible';
             loadelement.style.webkitTransform = 'translateY(0px)';
             loadelement.style.transform = 'translateY(0px)';
         });

    }, []);

    return (
        <div id="pricing-page" className='get-in-touch'> 
            <Helmet>
                <title>Pricing Plans</title>
                <meta name="description" 
                      content="We look forward to hearing from you about any way that we can help."
                      data-react-helmet="true"
                />
            </Helmet>
            <div className='services-top'>
                <div id="services-top-img-body" className='my-5'>
                    <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                </div>                
                <h1 className='services-header-title fade-in-up-fast'>Pricing Plans</h1>
            </div>
            <div className='services-top-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <div className='pricing-body'>
                <div className="container pricing-header">
                    <br/>    
                    <h1 className='portfolio-txt'>Affordable, flexible pricing</h1>
                    <p>Una offers peace of mind for businesses and nonprofits, without having to worry about hiring a full-time employee.
                        Below are our standard pricing plans, but we approach each project with a unique 
                        offer. <a href="/contact">Contact us </a> for a free quote.
                    </p>
                    <div className='switch-body'>
                        <div class="switch-button">
                            <span class="active"></span>
                            <button id="portfolio-left-btn" class="switch-button-case left active-case">BUSINESSES</button>
                            <button id="portfolio-right-btn" class="switch-button-case right">NONPROFITS</button>
                        </div>
                    </div>
                </div>
                <div id="before-card" className='container pricing-section'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='card fade-in-up-fast'>
                                <div className='label'>
                                    <h1>One-and-Done</h1>
                                </div>
                                <div className='plan'>
                                    <h1><span>$</span>899
                                        {/* <span className='month'>one-time charge</span> */}
                                    </h1>
                                    <p>Best for small businesses looking for a one-time design and build.</p>
                                    <h2>What's Included:</h2>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Collaborative Web Design</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Custom Web Development</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Mobile-Friendly Design</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Search Engine Optimization</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Ready to Easily Edit</ul>
                                    <div className="plan-button py-3">
                                        <a href='/contact'><button type="submit" className="main-btn-blue">Get Started</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='card main-plan fade-in-up-fast'>
                                <div className='label'>
                                    <h1>Peace of Mind</h1>
                                </div>
                                <div className='plan'>
                                    <h1><span>$</span>199
                                        <span className='month'>/month</span>
                                    </h1>
                                    <p>Best for small businesses needing constant monthly support on a budget.</p>
                                    <h2>What's Included:</h2>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>FREE Web Design</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Hand-Coded Web Development</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Full Maintenance & Hosting</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Unlimited Edits</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Up to 5 Pages</ul>
                                    <div className="plan-button py-3">
                                        <a href='/contact'><button type="submit" className="main-btn-blue">Get Started</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='card fade-in-up-fast'>
                                <div className='label'>
                                    <h1>Next Level Pro</h1>
                                </div>
                                <div className='plan'>
                                    <h1><span>$</span>399+
                                        <span className='month'>/month</span>
                                    </h1>
                                    <p>Best for larger businesses looking for advanced features and full design package.</p>
                                    <h2>What's Included:</h2>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Hand-Coded Web Development</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Lifetime Hosting & Updates</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Blog and/or E-commerce Integration</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Logo + Graphic Design</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>More than 5 Pages</ul>
                                    <div className="plan-button py-3">
                                        <a href='/contact'><button type="submit" className="main-btn-blue">Get Started</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
                <div id="after-card" className='container pricing-section'>
                    <div className='row'>
                        <div className='col-lg-4'>
                        <div className='card fade-in-up-fast'>
                                <div className='label'>
                                    <h1>One-and-Done</h1>
                                </div>
                                <div className='plan'>
                                    <h1><span>$</span>699
                                        {/* <span className='month'>/month</span> */}
                                    </h1>
                                    <p>Best for smaller nonprofits looking for a one-time design and build.</p>
                                    <h2>What's Included:</h2>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Collaborative Web Design</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Custom Web Development</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Mobile-Friendly Design</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Search Engine Optimization</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Ready to Easily Edit</ul>
                                    <div className="plan-button py-3">
                                        <a href='/contact'><button type="submit" className="main-btn-blue">Get Started</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='card main-plan fade-in-up-fast'>
                                <div className='label'>
                                    <h1>Peace of Mind</h1>
                                </div>
                                <div className='plan'>
                                    <h1><span>$</span>149
                                        <span className='month'>/month</span>
                                    </h1>
                                    <p>Best for smaller nonprofits needing constant monthly support on a budget.</p>
                                    <h2>What's Included:</h2>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>FREE Web Design</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Hand-Coded Web Development</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Full Maintenance & Hosting</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Unlimited Edits</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Up to 5 Pages</ul>
                                    <div className="plan-button py-3">
                                        <a href='/contact'><button type="submit" className="main-btn-blue">Get Started</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='card fade-in-up-fast'>
                                <div className='label'>
                                    <h1>Next Level Pro</h1>
                                </div>
                                <div className='plan'>
                                    <h1><span>$</span>299
                                        <span className='month'>/month</span>
                                    </h1>
                                    <p>Best for larger organizations looking for advanced features and full design package.</p>
                                    <h2>What's Included:</h2>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Hand-Coded Web Development</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Lifetime Hosting & Updates</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Blog and/or E-commerce Integration</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>Logo + Graphic Design</ul>
                                    <ul><i class="fa fa-check-circle" aria-hidden="true"></i>More than 5 Pages</ul>
                                    <div className="plan-button py-3">
                                        <a href='/contact'><button type="submit" className="main-btn-blue">Get Started</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
                <section id='bottom-pricing-section'>
                    <div>                              
                        <h1 className='why-una'>Get Started with a<span> Free Web Diagnosis!</span></h1>
                        <br/>
                        <p> Before design and development, we offer free web audits of your site. 
                            We check site speed, mobile-friendliness, accessibility, SEO, & overall design and give you 
                            a detailed diagnosis of issues and ways to improve - ON THE HOUSE.
                        </p>
                        <div className="portfolio-main-btn py-3">
                            <a href='/contact'><button type="submit" className="main-btn-blue">Get A Free Audit</button></a>
                        </div>
                    </div>
                </section>
                <br/>
            </div>
            <div id='bottom-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
        </div>
    );
}

export default Pricing;