import React from 'react';
import { useEffect } from 'react';

const Footer = () => {

    useEffect(() => {

        const darkToggle = document.getElementById("darkToggle");
        darkToggle.addEventListener("change", lightdark);
        
        const footerNavLinkDark = document.querySelectorAll("[id='nav-link-footer']");

        const navLinkPhone = document.getElementById("nav-link-phone");
        const navLinkEmail = document.getElementById("nav-link-email");

        function lightdark() {
            if(this.checked) {
                const footerNavLinkDarkArray = [...footerNavLinkDark];
                footerNavLinkDarkArray.forEach(id => {
                    id.style.color = 'white';
                });
                navLinkPhone.style.color = 'white';
                navLinkEmail.style.color = 'white';
            } else {
                const footerNavLinkDarkArray = [...footerNavLinkDark];
                footerNavLinkDarkArray.forEach(id => {
                    id.style.color = 'white';
                });
                navLinkPhone.style.color = 'white';
                navLinkEmail.style.color = 'white';
            }
        }
    }, []);

    return (
        <footer>
            <section id="footer-section">
                <div className='container'>
                    <div className='footer'>
                        <div className="row footer-top">
                            <div className="col-lg-5">
                                <img alt="Una Logo" className='footer-img' src="../assets/company_logo.png"/>
                                {/* <br/><br/>
                                <div className='footer-phone'>
                                    <a id='nav-link-phone' className="nav-link fa fa-phone fa-1x" href="tel:315-235-5889" aria-hidden="true">&nbsp;
                                    (315) 235-5889</a>
                                </div>
                                <br/>
                                <div className='footer-email'>
                                    <a  id='nav-link-email' className="nav-link fa fa-envelope fa-1x" href="mailto:info@unawebsolutions.com" aria-hidden="true">&nbsp;
                                        info@unawebsolutions.com
                                    </a>
                                </div> */}
                            </div>
                            <div className="col-lg-2 footer-links">
                                <h4 className='footer-title'>Quick Links</h4>
                                <br/>
                                <div className='footer-txt'>
                                    <p><a id='nav-link-footer' className='nav-link' href='/about'>About</a></p>
                                    <p><a id='nav-link-footer' className='nav-link' href='/web-development'>Services</a></p>
                                    <p><a id='nav-link-footer' className='nav-link' href='/work'>Work</a></p>
                                    <p><a id='nav-link-footer' className='nav-link' href='/pricing'>Pricing</a></p>
                                    <p><a id='nav-link-footer' className='nav-link' href='/contact'>Contact</a></p>
                                </div>
                            </div>
                            <div className="col-lg-2 footer-services">
                                <h4 className='footer-title'>Services</h4>
                                <br/>
                                <div className='footer-txt'>
                                    <p><a>Web Design</a></p>
                                    <p><a>Web Development</a></p>
                                    <p><a>Website Maintenance</a></p>
                                    <p><a>Tutoring & Consulting</a></p>
                                    <p><a>Hosting Services</a></p>
                                </div>
                            </div>
                            <div className="col-lg-3 footer-contact">
                                <h4 className='footer-title'>Contact</h4>
                                <br/>
                                <div className='footer-local'>
                                    <p><a className="nav-link" href="tel:315-235-5889" aria-hidden="true"><i className='fa fa-phone fa-1x'></i>(315) 235-5889</a></p>
                                    <p><a className="nav-link" href="mailto:info@unawebsolutions.com" aria-hidden="true"><i className='fa fa-envelope fa-1x'></i>info@unawebsolutions.com</a></p>
                                    <p><a><i className='fa  fa fa-map-marker fa-1x'></i>Upstate NY</a></p>
                                </div>
                            </div>  
                        <div className='row copyright-section'>
                            <hr/>
                            <div className='col-lg-4'>
                                <ul className="list-unstyled d-flex">
                                    <li className="ms-3">
                                        <a 
                                            href="https://www.facebook.com/profile.php?id=100088934117147"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fa fa-facebook fa-1x"></i>
                                        </a>
                                    </li>
                                    <li className="ms-3">
                                        <a
                                            href="https://www.instagram.com/unawebsolutions/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fa fa-instagram fa-1x"></i>
                                        </a>
                                    </li>
                                    <li className="ms-3">
                                        <a
                                            href="https://www.youtube.com/@UnaWebSolutions"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fa fa-youtube fa-1x"></i>
                                        </a>
                                    </li>
                                    <li className="ms-3">
                                        <a
                                            href="https://www.tiktok.com/@una.web.solutions"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fa fa-tiktok fa-1x"></i>
                                        </a>
                                    </li>
                                    <li className="ms-3">
                                        <a 
                                            href="https://www.linkedin.com/company/una-web-solutions"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fa fa-linkedin fa-1x"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-8 copyright-footer'>
                                <p className='copyright-txt'>Copyright © 2023 - 2024. Una Web Solutions. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </footer>
    );
}

export default Footer;