import React from 'react';
import { useEffect } from 'react';
import $ from 'jquery'; 
import {Helmet} from "react-helmet";
import JotformEmbed from 'react-jotform-embed';
import Popup from './Popup';

const Services = () => {

    useEffect(() => {            
            var nav = document.getElementById("navid");
    
            const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

            if (window.innerWidth < 700) {
                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'black';
                }   );
            }   
                        
            window.addEventListener("scroll", () => {
    
                var navimg = document.getElementById("imgid");
                navimg.style.width = '90px';
                navimg.style.height = '90px';
                nav.style.backgroundColor = 'white'; 
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";
    
                const navLinkDarkArray = [...navLinkDark];
                  navLinkDarkArray.forEach(id => {
                        id.style.color = 'black';
                  });
    
                if (!window.pageYOffset && window.innerWidth > 700) {
                    nav.style.backgroundColor = 'transparent'; 
                    nav.style.color = "black";
                    nav.style.transition = '0.5s';
                    nav.style.boxShadow = "none";
                    navimg.style.width = '120px';
                    navimg.style.height = '120px';
    
                    const navLinkDarkArray = [...navLinkDark];
                    navLinkDarkArray.forEach(id => {
                            id.style.color = 'white';
                    });
                }
             });

        (function () {
            "use strict";
          
            var items = document.querySelectorAll(".timeline li");
          
            function isElementInViewport(el) {
              var rect = el.getBoundingClientRect();
              return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                  (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
              );
            }
          
            function callbackFunc() {
              for (var i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                  items[i].classList.add("in-view");
                }
              }
            }
          
            // listen for events
            window.addEventListener("load", callbackFunc);
            window.addEventListener("resize", callbackFunc);
            window.addEventListener("scroll", callbackFunc);
          })();
          
          var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

          fadeElementsLoad.forEach(function(loadelement) {
      
              loadelement.style.opacity = 1;
              loadelement.style.visibility = 'visible';
              loadelement.style.webkitTransform = 'translateY(0px)';
              loadelement.style.transform = 'translateY(0px)';
          });

          window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
                fadeElements.forEach(function(element) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(0px)';
                    element.style.transform = 'translateY(0px)';
                });
            }
        });

    }, []);

    return (
        <div id="services-page" className='our-services'> 
            <Helmet>
                <title>Services / Web Development</title>
                <meta name="description" 
                      content="We offer collaborative design, custom code development, and year-long maintenance."
                />
            </Helmet>
            <div className='services-top'>
                <div id="services-top-img-body" className='my-5'>
                    <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                </div>                
                <h1 className='services-header-title fade-in-up-fast'>Web Development</h1>
            </div>
            <div className='services-top-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <div id="una-web-about">UNA WEB SOLUTIONS</div>
            <div id="una-web2-about">UNA WEB SOLUTIONS</div>
            <div id='services-wrapper' className='services-page-rows white-top'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img className="card-img" src="../assets/servicepics/collaborative.svg" />
                        </div>
                        <div className='col-lg-6'>
                            <div>
                                <time><span>1</span> Collaborative Design</time>
                                <p className='service-time-text fade-in-up'>
                                Before the coding magic happens, we will spend time working 
                                together designing your site to be exactly as envisioned. Our clients'
                                satisfaction is our top priority. For this reason, we will not
                                start developing the site until we are sure that you are happy with 
                                the design. 
                                </p>
                                <p className='service-time-text fade-in-up'>
                                 As part of our web design services, our in-house graphic designer 
                                 can create a logo if needed for no extra charge!
                                </p>
                                <img className="blue_arrow" src="../assets/blue_arrow.svg"/>
                            </div>
                        </div>
                    </div> 
                    <div className='row'> 
                        <div className='mobile-service col-lg-6'>
                            <img className="card-img fade-in-up" src="../assets/servicepics/custom.svg" />
                        </div>
                        <div className='col-lg-6'>
                            <div>
                                <time><span>2</span> Custom Code</time>
                                <p className='service-time-text fade-in-up'>
                                After we are set on a design, the coding begins. What sets us
                                apart is that we don't use website builders like WordPress, but 
                                meticulously code your entire site line by line. This ensures 
                                that your site is far more unique, responsive, and optimized.  
                                </p>
                                <p className='service-time-text fade-in-up'>40+ hours 
                                (and lots of coffee) later, your site will be completely 
                                custom coded. 
                                </p>
                                <img className="blue_arrow_left" src="../assets/blue_arrow.svg"/>
                            </div>
                        </div>
                        <div className='desktop-service col-lg-6'>
                            <img className="card-img fade-in-up" src="../assets/servicepics/custom.svg" />
                        </div>
                    </div>
                    <div className='row'> 
                        <div className='col-lg-6'>
                            <img className="card-img fade-in-up" src="../assets/servicepics/responsive.svg" />
                        </div>
                        <div className='col-lg-6'>
                            <div>
                                <time><span>3</span> Fully Responsive</time>
                                <p className='service-time-text fade-in-up'>
                                Did you know that 60% of all web traffic comes through mobile 
                                phones? Did you also know that mobile-friendly websites are
                                far more optimized and SEO-friendly? (More on this  
                                <span>
                                    <a href="#seo-service">
                                    &nbsp;below
                                    </a>
                                </span>). 
                                </p>
                                <p className='service-time-text fade-in-up'>
                                For this reason, we take time to make sure your 
                                website remains visually appealing regardless of device or screen size. 
                                We do this by rigorously testing how your site performs on mobile
                                devices before launch to ensure a smooth website experience.
                                </p>
                                <img className="blue_arrow" src="../assets/blue_arrow.svg"/>
                            </div>
                        </div>
                    </div>
                    <div className='row'> 
                        <div className='mobile-service col-lg-6'>
                            <img className="card-img fade-in-up" src="../assets/servicepics/seo.svg" />
                        </div>
                        <div className='col-lg-6'>
                            <section id='seo-service'>
                                <time><span>4</span> Search Engine Optimization</time>
                                <p className='service-time-text fade-in-up'>
                                Imagine SEO like the map of a busy city. Think of your website 
                                as a shop hidden among many others. SEO helps place clear signs on every
                                corner and optimizes your shop's location so that passersby (search engine users) 
                                can easily find and visit your store. 
                                </p>
                                <p className='service-time-text fade-in-up'> 
                                While reaching the top position on Google is never guaranteed,
                                we apply multiple techniques from Google's latest standards to ensure 
                                you stand out in the crowded city of the internet.
                                </p>
                                <img className="blue_arrow_left" src="../assets/blue_arrow.svg"/>
                            </section>
                        </div>
                        <div className='desktop-service col-lg-6'>
                            <img className="card-img fade-in-up" src="../assets/servicepics/seo.svg" />
                        </div>
                    </div>
                    <div className='row'> 
                        <div className='col-lg-6'>
                            <img className="card-img fade-in-up" src="../assets/servicepics/server.svg" />
                        </div>
                        <div className='col-lg-6'>
                            <div>
                                <time><span>5</span> Hosting Services</time>
                                <p className='service-time-text fade-in-up'>
                                Hosting is the essential foundation that brings your website to 
                                life on the internet. Without it, your site will not load. 
                                As we believe in making your website responsibilities ours, we will
                                securely host your website for a year, giving you peace of mind and 
                                allowing you to focus on growing your business.
                                </p>
                                <img className="blue_arrow" src="../assets/blue_arrow.svg"/>
                            </div>
                        </div>
                    </div>
                    <div className='last-service-row row'> 
                        <div className='mobile-service col-lg-6'>
                            <img className="card-img fade-in-up" src="../assets/servicepics/support.svg" />
                        </div>
                        <div className='col-lg-6'>
                            <div>
                                <time><span>6</span> Continued Support</time>
                                <p className='service-time-text fade-in-up'>
                                Even after your site is live, we're here to help! Far too often,
                                web designers create a website for a client, but then leave them
                                in the dark when there are issues, or requests for updates. 
                                </p>
                                <p className='service-time-text fade-in-up'>
                                    As part of our year-long service package, we will act as 
                                    your website manager.
                                </p>
                                <div className='service-time-text-list'>
                                    <ul>
                                        <p className='service-time-text-list-header fade-in-up'>Unlimited Updates</p>
                                        <p className='fade-in-up'>Want to change an image, price, or design? We'll do it with no extra charge for a year!</p>
                                    </ul>
                                    <ul>
                                        <p className='service-time-text-list-header fade-in-up'>24/7 Support</p>
                                        <p className='fade-in-up'>Is there an issue with the site you need fixed ASAP, let us know!</p>
                                    </ul>
                                    <ul>
                                        <p className='service-time-text-list-header fade-in-up'>Web Consulting</p>
                                        <p className='fade-in-up'>Have a quick question related to your website? Hit us up!</p>
                                    </ul>
                                </div>
                            </div>
                            <div className='mobile-btn'>
                                <div className='container home-btn-body'>
                                    <a href='/work'><button type="submit" className="main-btn-blue">View Our Work<i className='fa fa-arrow-right'></i></button></a>
                                </div>
                            </div>
                        </div>
                        <div className='desktop-service col-lg-6'>
                            <img className="card-img fade-in-up" src="../assets/servicepics/support.svg" />
                            <div className='container home-btn-body'>
                                <a href='/work'><button type="submit" className="main-btn-blue">View Our Work<i className='fa fa-arrow-right'></i></button></a>
                            </div>
                        </div>
                    </div>
                    <div>
                        
                </div>                    
            </div>
            <section id="faq-section">
                <br/><br/><br/>
                <div id="faq-body" className="container">
                    <h1 id='contactTxtDark' className='contact-txt'>Frequently Asked Questions</h1>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="faq-drawer">
                                <input className="faq-drawer__trigger" id="faq-drawer" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer"> How much does a website cost? </label>
                                <div className="faq-drawer__content-wrapper">
                                    <div className="faq-drawer__content">
                                    <p>
                                    At <span id="unablue1">Una</span>,  we approach each website as a unique project. For this reason, the cost depends on the individual needs of each client. 
                                    For more details, check out our <a id="pricing-link" href="/pricing">pricing plans.</a>
                                    </p>
                                    <p>
                                    If your website needs anything more than the standard 5 pages, this translates to more time and a higher price. However, <strong>we will work with you to accommodate to any budget.</strong>                        </p>
                                    <p>
                                    We are flexible with how payments are made. You can pay one lump sum, half up front and half when the site is launched or even break down the cost into easier-to-manage <strong>monthly payments</strong>. Either way, your custom coded website will be up and running for at least a full year!                         </p>
                                    </div>
                                </div>
                                </div>

                                <div className="faq-drawer">
                                <input className="faq-drawer__trigger" id="faq-drawer-2" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer-2"> How long does it take to build a website?</label>
                                <div className="faq-drawer__content-wrapper">
                                    <div className="faq-drawer__content">
                                    <p>
                                        Because we meticulously code every website line by line, your standard 5-page site will be launched within approximately 3-4 weeks. This may vary depending on the number of pages and/or complexity of the design.                                  
                                    </p>
                                    </div>
                                </div>
                                </div>
                                
                                <div className="faq-drawer">
                                <input className="faq-drawer__trigger" id="faq-drawer-3" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer-3"> Can I edit my website after it is done?</label>
                                <div className="faq-drawer__content-wrapper">
                                    <div className="faq-drawer__content">
                                    <p>
                                    Yes! We completely understand that opinions change and we allow for unlimited editing of your website throughout the entire first year of services. Simply reach out with your idea and we will change the website to match exactly what you envision.
                                    </p>
                                    <p>
                                        If you would like to edit the site after the first year is over and the original package's services are done, we charge a rate of $50 for every hour of editing after that point.
                                    </p>
                                    </div>
                                </div>
                                </div>

                                <div className="faq-drawer">
                                <input className="faq-drawer__trigger" id="faq-drawer-4" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer-4"> What if I really want to use a website builder?</label>
                                <div className="faq-drawer__content-wrapper">
                                    <div className="faq-drawer__content">
                                    <p>
                                    We specialize in and highly recommend,  custom coded websites. Read more about why custom is better
                                        <span>
                                        <a href="https://www.instagram.com/p/CsyzkP9O6eC/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
                                                target="_blank"
                                                rel="noreferrer"
                                        > here
                                        </a>
                                    </span>. However, in select cases, we will accommodate to your needs and use CMS builders like Squarespace (while making sure it keeps a custom design)                                </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-6'>
                            <div className="faq-drawer">
                            <input className="faq-drawer__trigger" id="faq-drawer-5" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer-5"> Who hosts the website?</label>
                            <div className="faq-drawer__content-wrapper">
                                <div className="faq-drawer__content">
                                <p>
                                We do! Our primary goal at <span id="unablue1">Una</span> is to make <i>your responsibility our responsibility</i>. Between juggling the list of responsibilities of a business, we believe maintaining the website should not be on that list. For this reason we handle the entire hosting process. We register your domain, host your site, and worry about any potential server issues, all as part of the year-long services package.  
                                </p>
                                <p>
                                If you would like for us to maintain your site after the first year is over and the original package's services are done, we charge a rate of $50 per month for maintenance after that point.
                                </p>
                                </div>
                            </div>
                            </div>

                            <div className="faq-drawer">
                            <input className="faq-drawer__trigger" id="faq-drawer-6" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer-6"> Can you redesign a website I already have?</label>
                            <div className="faq-drawer__content-wrapper">
                                <div className="faq-drawer__content">
                                <p>
                                Yes! Do keep in mind however that with <span id="unablue1">Una</span>, you are paying for a complete package of all the services we offer, so your previous site will have to be replaced by our complete package. This means that any cookie-cutter platform used to make your previous site will <i>NOT</i> be used, instead you will start off fresh with our custom coding service. 
                                </p>
                                </div>
                            </div>
                            </div>
                            
                            <div id="last-faq" className="faq-drawer">
                            <input className="faq-drawer__trigger" id="faq-drawer-7" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer-7"> What sets  <span id="unablue1">Una</span> Web Solutions apart?</label>
                            <div className="faq-drawer__content-wrapper">
                                <div className="faq-drawer__content">
                                <p>
                                Our <i>mission</i>. We strive to empower small businesses and nonprofits to better serve their local community. We will work with you to deliver a high-quality website that enhances your online presence.  
                                </p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="services-wave" id='bottom-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <Popup/>
        </div>
            
                    
    );
}

export default Services; 
