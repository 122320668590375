import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import Popup from './Popup';

const About = () => {

    useEffect(() => {            
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

            if (window.innerWidth < 700) {
                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'black';
                }   );
            }

        
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }
         });

        var fadeElementsLoad = document.querySelectorAll('.fade-in-up-load');

         fadeElementsLoad.forEach(function(loadelement) {
     
             loadelement.style.opacity = 1;
             loadelement.style.visibility = 'visible';
             loadelement.style.webkitTransform = 'translateY(0px)';
             loadelement.style.transform = 'translateY(0px)';
         });

        var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

        fadeElementsLoad.forEach(function(loadelement) {
    
            loadelement.style.opacity = 1;
            loadelement.style.visibility = 'visible';
            loadelement.style.webkitTransform = 'translateY(0px)';
            loadelement.style.transform = 'translateY(0px)';
        });

        window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
                fadeElements.forEach(function(element) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(0px)';
                    element.style.transform = 'translateY(0px)';
                });
            }
        });

        const counters = document.querySelectorAll(".counter");

        counters.forEach((counter) => {
          counter.innerText = "0";
          const updateCounter = () => {
            const target = +counter.getAttribute("data-target");
            const count = +counter.innerText;
            const increment = target / 300;
            if (count < target) {
              counter.innerText = `${Math.ceil(count + increment)}`;
              setTimeout(updateCounter, 1);
            } else counter.innerText = target;
          };
          updateCounter();
        });

        
    }, []);

    return (
        <div id="about-page">
            <Helmet>
                <title>About Us</title>
                <meta name="description" 
                      content="Una Web Solutions is a web design 
                            company started to empower small businesses and 
                            nonprofits."
                />
            </Helmet>
            <div className='services-top'>
                <div id="services-top-img-body" className='my-5'>
                    <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                </div>                
                <h1 className='services-header-title fade-in-up-fast'>About Us</h1>
            </div>
            <div className='services-top-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <div id='about-wrapper' className="about white-top container">
                    <br/><br/><br/>
                        <div id="about-page-body" className="align-items-center">
                            <div className='about-top-txt'>
                                <div id='about-story-mission' className='row'>
                                    <div id='about-story' className='col-lg-6'>
                                        <h1 className='fade-in-up-load'>Our <span id='unablue1'>Story</span></h1>
                                        <p className='fade-in-up'><span id='unablue1'>Una</span> Web Solutions is a web 
                                        design, development, and tutoring company started with the goal of working directly
                                        with web design clients and beginner programmers to grow their online impact.
                                        </p>
                                    </div>
                                    <div id='about-mission' className='col-lg-6'>
                                        <h1 className='fade-in-up-load'>Our <span id='unablue1'>Mission</span></h1>
                                        <p className='fade-in-up'>At the core of <span id='unablue1'>Una </span> 
                                            is the mission of community action. For this reason, we ensure
                                            that <span id='unablue1'>2.5%</span> of the total earnings of every project 
                                            is donated towards nonprofits and humanitarian efforts across the world.
                                        </p>
                                    </div>
                                </div>
                            </div><br/>
                            <section id="home-numbers-section">
                                <div>  
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div class="card">                              
                                                <h2 className='counter plus fade-in-up' data-target="4500"></h2>
                                                <p className='fade-in-up'>
                                                Lines of Code Written Per Website
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div class="card">                                                          
                                                <h2 className='counter plus fade-in-up' data-target="80"></h2>
                                                <p className='fade-in-up'>
                                                Hours Spent on Each Project
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div class="card">                              
                                                <h2 className='counter plus fade-in-up' data-target="25"></h2>
                                                <p className='fade-in-up'>
                                                Hours of 1-on-1 Online Tutoring
                                                </p>
                                            </div>
                                        </div>
                                    </div>                 
                                </div>
                            </section>
                            <iframe  src="https://www.youtube.com/embed/T4aTNHKyLvk?si=oR8zPjI08IHc_iIB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <br/>      
                            <section class="showcase">
                                <img alt="Una River" src="../assets/unariverbackground.jpeg"/>
                                <div class="overlay">
                                    <br/>
                                    <div id="whyunatext">
                                        <h3 id='namebehindcompany'>The Name Behind the Company</h3>
                                        <p>Spanning across north-western Bosnia & Herzegovina, the Una River 
                                            is one of the many rivers that flow within the country. 
                                        </p>
                                        <p>
                                            Local legends say the river gained its' name from the Romans who,
                                            upon seeing it for the first time, called it <i>una</i> (latin for "one") due to
                                            the river's unique beauty.
                                        </p>
                                        <p>
                                            Una remains a valued part of Bosnian culture and a frequent tourist
                                            destination for those in the northwestern region.
                                        </p>
                                        <p>
                                            <span id='unablue1'>Una</span> Web Solutions was started to help small businesses achieve a similarly 
                                            unique beauty for their website.
                                        </p>
                                    </div>
                                </div>
                            </section>
                            <section id='about-page-btn-section'>
                                <div className=''>                              
                                    <h1 className='why-una'>Why <span>Una</span>?</h1>
                                    <br/>
                                    <p> With our unique custom-code approach to web development, Una Web Solutions creates websites that 
                                        stick out from the cookie-cutter sites made by website builders. Learn more about our services below.
                                    </p>
                                    <div className="about-main-btn py-3">
                                        <a href='/web-development'><button type="submit" className="main-btn-blue">Our Services</button></a>
                                    </div>
                                </div>
                            </section>
                            </div>
                        </div>
                    <br/><br/>
                    <div className="about-wave" id='bottom-wave'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                    </div>
                    <Popup/>
                </div>
    );
}

export default About;