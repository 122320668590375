import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const PageNotFound = () => {

    useEffect(() => {        
        
        const nav = document.getElementById("navid");
        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");


        nav.style.backgroundColor = '#ffff';

        const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                id.style.color = 'black';
         });
         


        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }

    }, []);

    return ( 
        <div>
            <Helmet>
                <title>404: Page Not Found</title>
                <meta name="description" 
                      content="Una Web Solutions is a web design 
                            company started to empower small businesses and 
                            nonprofits."
                />
            </Helmet>
            <section className='pagenotfound-section'>
                <div className='pagenotfound-img'>
                    <img src='assets/404.png'/>
                </div>
                <h1>Oops!</h1>
                <h2>Sorry. We couldn't find the page you're looking for.</h2>
                <h2>Please check the URL for any typos.</h2>
                <button type="submit" className="main-btn-blue"><a href='/'>Go Back Home</a></button>
            </section>
            <div id='bottom-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
        </div>
    );
}

export default PageNotFound;