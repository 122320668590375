import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import Popup from './Popup';

const Portfolio = () => {

    useEffect(() => {            
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }   
                
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
              });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }
         });

         var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

         fadeElementsLoad.forEach(function(loadelement) {
     
             loadelement.style.opacity = 1;
             loadelement.style.visibility = 'visible';
             loadelement.style.webkitTransform = 'translateY(0px)';
             loadelement.style.transform = 'translateY(0px)';
         });

        // 'use strict';

        var afterCard               = document.getElementById('after-card');
        var beforeCard               = document.getElementById('before-card');

        afterCard.style.display = 'none';

        var switchButton 			= document.querySelector('.switch-button');
        var switchBtnRight 			= document.querySelector('.switch-button-case.right');
        var switchBtnLeft 			= document.querySelector('.switch-button-case.left');
        var activeSwitch 			= document.querySelector('.active');

        function switchLeft(){
            switchBtnRight.classList.remove('active-case');
            switchBtnLeft.classList.add('active-case');
            activeSwitch.style.left 						= '0%';
            beforeCard.style.display = 'block';
            afterCard.style.display = 'none';
        }

        function switchRight(){
            switchBtnRight.classList.add('active-case');
            switchBtnLeft.classList.remove('active-case');
            activeSwitch.style.left 						= '50%';
            beforeCard.style.display = 'none';
            afterCard.style.display = 'block';

        }

        switchBtnLeft.addEventListener('click', function(){
            switchLeft();
        }, false);

        switchBtnRight.addEventListener('click', function(){
            switchRight();
        }, false);
        
       // Get all image elements
        const imageElements = document.querySelectorAll('.portfolio-img-body');

        // Get all content elements
        const contentElements = document.querySelectorAll('.portfolio-img-content');

        // Attach click event listeners to the image elements
        imageElements.forEach((imageElement) => {
            imageElement.addEventListener('click', () => {
                // Get the data-image attribute to determine which content to show
                const imageNumber = imageElement.getAttribute('data-image');

                // Hide all content elements
                contentElements.forEach((contentElement) => {
                    contentElement.classList.remove('active-content');
                });

                // Show the content associated with the clicked image
                const activeContent = document.querySelector(`[data-content="${imageNumber}"]`);
                activeContent.classList.add('active-content');


                const portfolioLeftBtn = document.getElementById("portfolio-left-btn");
                const portfolioRightBtn = document.getElementById("portfolio-right-btn");
        
                const portfolioCnyDrones = document.getElementById("portfolio-cny-drones");
                const mucc = document.getElementById("mucc");
        
                portfolioLeftBtn.addEventListener('click', () => {

                    contentElements.forEach((contentElement) => {
                        contentElement.classList.remove('active-content');
                    });

                    portfolioCnyDrones.classList.add('active-content');
                    
                });
        
                portfolioRightBtn.addEventListener('click', () => {

                    contentElements.forEach((contentElement) => {
                        contentElement.classList.remove('active-content');
                    });

                    mucc.classList.add('active-content');

    
                });

            });
        });

        window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
                fadeElements.forEach(function(element) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(0px)';
                    element.style.transform = 'translateY(0px)';
                });
            }
        });
        

    }, []);

    return (
        <div id="portfolio-page" className='our-work'>
            <Helmet>
                <title>Our Work</title>
                <meta name="description" 
                      content="All Una Sites are Custom Coded, Fully Responsive, and Completely Unique"
                />
            </Helmet>
                <div className='services-top'>
                    <div id="services-top-img-body" className='my-5'>
                        <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                    </div>                
                    <h1 className='services-header-title fade-in-up-fast'>Our Work</h1>
                </div>
                <div className='services-top-wave'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                </div>
                <div className='portfolio-body'>
                    <div className='row'>
                        <div className='col-lg-10 portfolio-header'>
                            <h1 className='portfolio-sub-header'>OUR WORK</h1>
                            <h1 className='portfolio-txt'>Meet Some of Our Clients</h1>
                        </div>
                        <div className='col-lg-2'>
                            <div className='switch-body'>
                                <div class="switch-button">
                                    <span class="active"></span>
                                    <button id="portfolio-left-btn" class="switch-button-case left active-case">BUSINESSES</button>
                                    <button id="portfolio-right-btn" class="switch-button-case right">NONPROFITS</button>
                                </div>
                            </div>
                        </div>
                    </div>                
                        <div className='portfolio-examples'>
                            <div>
                                <div id="extra-service-card">
                                    <div id="before-card">
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <div className='portfolio-img-body fade-in-up' data-image="1">
                                                    <img id="cnydrones-img" alt="CNY Drone Services" className='portfolio-img' src='../assets/cnydrones_una.png'/>
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='portfolio-img-body fade-in-up' data-image="2">
                                                    <img id="ida-img" alt="CNY Drone Services" className='portfolio-img' src='../assets/idajewelry_site.jpg'/>
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='portfolio-img-body fade-in-up' data-image="3">
                                                    <img id="eds-img" alt="CNY Drone Services" className='portfolio-img' src='../assets/edslogos_website.png'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="after-card">
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <div className='portfolio-img-body fade-in-up' data-image="4">
                                                    <img id="mucc-img" alt="CNY Drone Services" className='portfolio-img' src='../assets/mucc_site.jpg'/>
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='portfolio-img-body fade-in-up' data-image="5">
                                                    <img id="ochc-img" alt="CNY Drone Services" className='portfolio-img' src='../assets/ochc.jpg'/>
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='portfolio-img-body fade-in-up' data-image="6">
                                                    <img id="muccbots-img" alt="CNY Drone Services" className='portfolio-img' src='../assets/muccbots.jpg'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div id='portfolio-cny-drones' className='portfolio-popup portfolio-img-content active-content' data-content="1">
                            <div className='container'>
                                <div className='portfolio-popup-top-img'>
                                    <img alt="CNY Drone Services" src='../assets/portfolio/cnydrones-popup.webp'/>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='portfolio-menu'>
                                            <h2>CLIENT</h2>
                                            <div className='portfolio-link fade-in-up'>
                                                <a target="_blank"
                                                    href="https://cnydroneservices.com/">cnydroneservices.com</a>
                                            </div>
                                            <h2 className='fade-in-up'>SERVICES</h2>
                                            <p className='fade-in-up'>Web Design</p>
                                            <p className='fade-in-up'>Hand-Coded Development</p>
                                            <p className='fade-in-up'>Contact Form Integration</p>
                                            <p className='fade-in-up'>24/7 Hosting & Maintenance</p>
                                            <p className='fade-in-up'>Unlimited Edits</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-9'>
                                        <div className='portfolio-content'>
                                            <p className='fade-in-up'>CNY Drone Services Inc. is a drone service provider firm, 
                                                specializing in Agricultural, Aggregate, Construction, and more.  
                                                Offering services such as Photogrammetry, Mapping, and Aerial 
                                                Spraying/Seeding. 
                                                They are insured, licensed, professionals based in Herkimer/Utica NY 
                                                and proudly serve all of NYS and beyond!                                            
                                            </p>
                                            <p className='fade-in-up'>Rick and the CNY Drone Services team came to us seeking a redesign of
                                                their original website, built by a website builder. They were not
                                                satisfied with the cookie-cutter design of their old site. After working
                                                directly with the team, we created a unique design. 
                                            </p>
                                            <p className='fade-in-up'>The result is a brand-new redesign that is 100% hand-coded. Their business 
                                                has been enhanced in multiple ways since then. First, we set up a year-long 
                                                maintenance service, meaning they don't need to spend their time updating 
                                                the site as we do all edits. Second, we integrated a contact form system 
                                                which automatically fills an online document with all contact information,
                                                streamlining their data management and lead generation process.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='portfolio-popup portfolio-img-content' data-content="2">
                            <div className='container'>
                                <div className='portfolio-popup-top-img'>
                                    <img alt="CNY Drone Services" src='../assets/portfolio/ida-popup.webp'/>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='portfolio-menu'>
                                            <h2>CLIENT</h2>
                                            <div className='portfolio-link'>
                                                <a target="_blank"
                                                    href="https://idajewelryshop.com/">idajewelryshop.com</a>
                                            </div>
                                            <h2 className='fade-in-up'>SERVICES</h2>
                                            <p className='fade-in-up'>Web & Logo Design</p>
                                            <p className='fade-in-up'>Hand-Coded Development</p>
                                            <p className='fade-in-up'>Blog Integration</p>
                                            <p className='fade-in-up'>24/7 Hosting & Maintenance</p>
                                            <p className='fade-in-up'>Unlimited Edits</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-9'>
                                        <div className='portfolio-content'>
                                            <p className='fade-in-up'>     
                                            Ida Jewelry LLC is an affiliate marketing company owned and run by 
                                            stay-at-home mom Ida Dizdarevic. With over 10+ years of experience 
                                            | 19k+ items sold | 1.3k eBay followers, Ida Jewelry LLC exists to
                                            connect customers with luxury and vintage jewelry of all kind, as
                                            recommended by a jewelry expert.                                    
                                            </p>
                                            <p className='fade-in-up'>     
                                            Being an affiliate marketing company, the main focus on our services
                                            provided to Ida Jewelry was to design and develop a website with SEO
                                            in mind. We designed a website with built-in blog integration, allowing
                                            Ida to share her expertise and recommendations to her growing audience.                                     
                                            </p>
                                            <p className='fade-in-up'>
                                            Beyond developing the website, we also designed a logo and integrated 
                                            an email marketing platform, enhancing the user experience and 
                                            client communication.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='portfolio-popup portfolio-img-content' data-content="3">
                            <div className='container'>
                                <div className='portfolio-popup-top-img'>
                                    <img alt="CNY Drone Services" src='../assets/portfolio/edslogos-popup.webp'/>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='portfolio-menu'>
                                            <h2>CLIENT</h2>
                                            <div className='portfolio-link'>
                                                <a target="_blank"
                                                    href="https://edslogos.com/">edslogos.com</a>
                                            </div>
                                            <h2 className='fade-in-up'>SERVICES</h2>
                                            <p className='fade-in-up'>Web Design</p>
                                            <p className='fade-in-up'>Web Development</p>
                                            <p className='fade-in-up'>Contact Form Integration</p>
                                            <p className='fade-in-up'>24/7 Hosting & Maintenance</p>
                                            <p className='fade-in-up'>Unlimited Edits</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-9'>
                                        <div className='portfolio-content'>
                                            <p className='fade-in-up'>
                                            Ed's Logos is a freelance graphic designer from New York with a focus on logo design. As a logo designer, 
                                            he specializes in designing logos for clients ranging from small businesses to nonprofits to startups, 
                                            and anything in-between.                                            
                                            </p>
                                            <p className='fade-in-up'>
                                            As a graphic designer, we worked closely with Ed to design a website which showcases 
                                            his graphic design expertise and creativity. The website built for Ed's Logos features an
                                            interactive portfolio section, allowing visiters to both view his past work and learn 
                                            more about his clients. 
                                            </p>
                                            <p className='fade-in-up'>Other features include responsive (mobile-friendly) design, contact form integration, 
                                                search engine optimization, hosting & maintenance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='mucc' className='portfolio-popup portfolio-img-content' data-content="4">
                            <div className='container'>
                                <div className='portfolio-popup-top-img'>
                                    <img alt="CNY Drone Services" src='../assets/portfolio/mucc-popup.webp'/>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='portfolio-menu'>
                                            <h2>CLIENT</h2>
                                            <div className='portfolio-link'>
                                                <a target="_blank"
                                                    href="https://www.midtownutica.org/">midtownutica.org</a>
                                            </div>
                                            <h2 className='fade-in-up'>SERVICES</h2>
                                            <p className='fade-in-up'>Web Design</p>
                                            <p className='fade-in-up'>Web Development</p>
                                            <p className='fade-in-up'>Event Calendar Integration</p>
                                            <p className='fade-in-up'>24/7 Hosting & Maintenance</p>
                                            <p className='fade-in-up'>Unlimited Edits</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-9'>
                                        <div className='portfolio-content'>
                                            <p className='fade-in-up'>
                                            The Midtown Utica Community Center (MUCC) is an inclusive and 
                                            multicultural community center in Utica, New York. MUCC is anti-racist, 
                                            multilingual, and refugee-friendly. Their programming is designed to 
                                            enhance the safety, health, and educational opportunities of their 
                                            diverse community.                                            
                                            </p>
                                            <p className='fade-in-up'>
                                            The new website effortlessly communicates MUCC's mission, making 
                                            it a hub for residents seeking information about events, programs, 
                                            and local resources. Features we implemented, such as the event calendar,
                                            gallery, and resources pages create an online space which is inclusive
                                            and easy to navigate for all populations.
                                            </p>
                                            <p className='fade-in-up'>
                                            The website has not only increased MUCC's online visibility but 
                                            also enables the nonprofit to better serve its community, promoting 
                                            inclusivity, accessibility, and the growth of local programs. Our
                                            continuous support has been a profound honor, as we partner directly 
                                            with the MUCC team to offer extended support for refugees in accessing 
                                            essential resources and integrating into their new homes in Utica.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='portfolio-popup portfolio-img-content' data-content="5">
                            <div className='container'>
                                <div className='portfolio-popup-top-img'>
                                    <img alt="CNY Drone Services" src='../assets/portfolio/ochc-popup.webp'/>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='portfolio-menu'>
                                            <h2>CLIENT</h2>
                                            <div className='portfolio-link'>
                                                <a target="_blank"
                                                    href="https://oneidacountyhistory.org/">oneidacountyhistory.org</a>
                                            </div>
                                            <h2 className='fade-in-up'>SERVICES</h2>
                                            <p className='fade-in-up'>Web Design</p>
                                            <p className='fade-in-up'>Web Development</p>
                                            <p className='fade-in-up'>Contact Form Integration</p>
                                            <p className='fade-in-up'>Event Calendar Integration</p>
                                            <p className='fade-in-up'>24/7 Hosting & Maintenance</p>
                                            <p className='fade-in-up'>Unlimited Edits</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-9'>
                                        <div className='portfolio-content'>
                                            <p className='fade-in-up'>
                                            Founded in 1876, Oneida County History Center collects and commemorates 
                                            the history of Central New York in general and County of Oneida in particular.
                                            The History Center seeks to make this rich heritage readily available to researchers, 
                                            families, and students enhancing the community's knowledge and appreciation 
                                            of its history.                                            
                                            </p>
                                            <p className='fade-in-up'>
                                            The new site features a modern design, streamlined menus, and more content, 
                                            including organizational history, staff bios, and an event calendar. As part of 
                                            our monthly 24/7 hosting & maintenance service, we work directly with the History
                                            Center staff to regularly add new content in order to keep their members and 
                                            visitors informed on events, exhibits, programs, and more! 
                                            </p>
                                            <p className='fade-in-up'>
                                            This project is a great example of the importance of maintaining an online presence
                                            in the current digital age. Learning history is very important and the best way 
                                            to do so today is through a digital medium.   
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='portfolio-popup portfolio-img-content' data-content="6">
                            <div className='container'>
                                <div className='portfolio-popup-top-img'>
                                    <img alt="CNY Drone Services" src='../assets/portfolio/muccbots-popup.webp'/>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='portfolio-menu'>
                                            <h2>CLIENT</h2>
                                            <div className='portfolio-link'>
                                                <a target="_blank"
                                                    href="https://muccbots.com/">muccbots.com</a>
                                            </div>
                                            <h2 className='fade-in-up'>SERVICES</h2>
                                            <p className='fade-in-up'>Web Design</p>
                                            <p className='fade-in-up'>Web Development</p>
                                            <p className='fade-in-up'>Contact Form Integration</p>
                                            <p className='fade-in-up'>24/7 Hosting & Maintenance</p>
                                            <p className='fade-in-up'>Unlimited Edits</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-9'>
                                        <div className='portfolio-content'>
                                            <p className='fade-in-up'>The MUCCbots (FTC 13510) is a robotics club comprised of 
                                                talented and passionate high school students based out of the 
                                                Midtown Utica Community Center. Their club focuses on designing, 
                                                building, and programming robots that can complete complex tasks 
                                                and compete in local competitions.                                       
                                            </p>
                                            <p className='fade-in-up'>
                                                The website built for the MUCCbots acts a type of digital outreach 
                                                for the club, enhancing their online presence and connecting
                                                them with sponsors and other memebers of the FIRST community.
                                            </p>
                                            <p className='fade-in-up'>
                                                The design was created in collaboration with the entire team
                                                and incorporates a building-block design to match the team's 
                                                mechanical engineering skills.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section id='about-page-btn-section'>
                            <div>                              
                                <h1 className='why-una'>Let's Get Started on<span> Your Site!</span></h1>
                                <br/>
                                <p> With our unique custom-code approach to web development, Una Web Solutions creates websites that 
                                    stick out from the cookie-cutter sites made by website builders. Click below to get a free quote.
                                </p>
                                <div className="portfolio-main-btn py-3">
                                    <a href='/contact'><button type="submit" className="main-btn-blue">Get A Free Quote</button></a>
                                </div>
                            </div>
                        </section>
                    </div>
                    <br/>
            <div id='bottom-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <Popup/>
        </div>
    );
}

export default Portfolio