import './styles/App.css';
import './styles/about.css';
import './styles/contact.css';
import './styles/footer.css';
import './styles/home.css';
import './styles/navbar.css';
import './styles/portfolio.css';
import './styles/services.css';
import './styles/blog.css';
import './styles/pricing.css';
import './styles/reviews.css';
import './styles/pagenotfound.css';
import Home from './component/Home';
import Contact from './component/Contact';
import About from './component/About';
import PageNotFound from './component/PageNotFound';
import Services from './component/Services';
import Education from './component/Education';
import { Routes, Route } from 'react-router-dom';
import Portfolio from './component/Portfolio';
import Pricing from './component/Pricing';
import Reviews from './component/Reviews';
import Popup from './component/Popup';
import WebDesignBusinessGuide from './component/WebDesignBusinessGuide';

function App() {
  return (
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="" element={<Home />} />
        <Route path="about" element={<About/>} />
        <Route path="contact" element={<Contact/>} />
        <Route path="web-development" element={<Services/>} />
        <Route path="education" element={<Education/>} />
        <Route path="work" element={<Portfolio/>} />
        <Route path="pricing" element={<Pricing/>} />
        <Route path="reviews" element={<Reviews/>} />
        <Route path="web-design-business-guide" element={<WebDesignBusinessGuide/>} />
      </Routes>
  );
}

export default App; 
