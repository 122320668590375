import React from 'react';
import { useEffect } from 'react';

const Navbar = () => {

    useEffect(() => {

  }, []);

    return (
      <nav id='navid' className="navbar fixed-top navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="/"><img id='imgid' alt='Una logo' className='unapic' src='../assets/company_logo.png'/></a>
         <button className="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
             
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a id='nav-link-dark' className="nav-link" href="/about">About</a>
              </li>
              <li className="nav-item">
                <a id='nav-link-dark' className="nav-link" href="/web-development">Web Design</a>
              </li>
              <li className="nav-item">
                <a id='nav-link-dark' className="nav-link" href="/education">Education</a>
              </li>
              {/* <li id="serviceslink" className="nav-item">
                  <div className="dropdown" >
                    <button id="dropdown_btn" className="dropbtn">
                    <a id='nav-link-dark' href="/web-development">Services</a>
                    </button>
                    <div className="dropdown-content tutoring-nav">
                      <a href="/web-development">Web Design & Dev</a>
                      <a href="/tutoring">Tutoring & Education</a>
                    </div>
                  </div> 
              </li> */}
              <li id="serviceslink" className="nav-item">
                  <div className="dropdown" >
                    <button id="dropdown_btn" className="dropbtn">
                    <a id='nav-link-dark' href="/work">Work</a>
                    </button>
                    <div className="dropdown-content">
                      <a href="/work">Case Studies</a>
                      <a href="/reviews">Reviews</a>
                    </div>
                  </div> 
              </li>
              {/* <li className="nav-item">
                <a id='nav-link-dark' className="nav-link" href="/pricing">Pricing</a>
              </li> */}
              <li className="nav-item">
                <a id='nav-link-dark' className="nav-link" href="/contact">Contact</a>
              </li>
            </ul>
              <div className='lightdark-mode'>
                <a id='lightdark'>LIGHT&nbsp;</a>
                <label className="switch">
                  <input id='darkToggle' type="checkbox"/>
                  <span className="slider round"></span>
                </label>
                <a id='lightdark'>&nbsp;DARK</a>
              </div>
        </div>
      </div>
    </nav>
    );
}

export default Navbar;