import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './component/Home';
import Navbar from './component/Navbar';
import Footer from './component/Footer';
import Contact from './component/Contact';
import About from './component/About';
import Services from './component/Services';
import Education from './component/Education';
import PageNotFound from './component/PageNotFound';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Portfolio from './component/Portfolio';
import Pricing from './component/Pricing';
import Reviews from './component/Reviews';
import Popup from './component/Popup';
import WebDesignBusinessGuide from './component/WebDesignBusinessGuide';
import ReactGA from "react-ga4";  

ReactGA.initialize("G-MRTGBC53HC");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Navbar/>
    <Routes>
        <Route path="" element={<App/>}>
          <Route index element={<Home/>}/>
          <Route path="*" element={<PageNotFound />} />
          <Route path="about" element={<About/>} />
          <Route path="contact" element={<Contact/>}/>
          <Route path="web-development" element={<Services/>}/>
          <Route path="education" element={<Education/>}/>
          <Route path="portfolio" element={<Portfolio/>}/>
          <Route path="pricing" element={<Pricing/>} />
          <Route path="reviews" element={<Reviews/>} />
          <Route path="web-design-business-guide" element={<WebDesignBusinessGuide/>} />
        </Route>
      </Routes>
    <Footer/>
  </BrowserRouter>
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
