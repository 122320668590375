
import React from 'react';
import { useEffect } from 'react';
import $ from 'jquery'; 
import {Helmet} from "react-helmet";
import Popup from './Popup';

const WebDesignBusinessGuide = () => {

    useEffect(() => {            
        
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }   
                
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
              });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }
         });

        (function () {
            "use strict";
          
            var items = document.querySelectorAll(".timeline li");
          
            function isElementInViewport(el) {
              var rect = el.getBoundingClientRect();
              return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                  (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
              );
            }
          
            function callbackFunc() {
              for (var i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                  items[i].classList.add("in-view");
                }
              }
            }
          
            // listen for events
            window.addEventListener("load", callbackFunc);
            window.addEventListener("resize", callbackFunc);
            window.addEventListener("scroll", callbackFunc);
          })();
          
          var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

          fadeElementsLoad.forEach(function(loadelement) {
      
              loadelement.style.opacity = 1;
              loadelement.style.visibility = 'visible';
              loadelement.style.webkitTransform = 'translateY(0px)';
              loadelement.style.transform = 'translateY(0px)';
          });


          window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
              fadeElements.forEach(function(element) {
                  element.style.opacity = 1;
                  element.style.visibility = 'visible';
                  element.style.webkitTransform = 'translateY(0px)';
                  element.style.transform = 'translateY(0px)';
              });
          }
          });

          

          window.onscroll = function (e) {
            var constantY = 300;
            var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
            var sidebars = document.getElementsByClassName("sidebar");
            var body = document.body;
            var html = document.documentElement;

            var documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            var windowHeight = window.innerHeight;
            var scrolledToBottom = (windowHeight + scrollTop >= documentHeight - 1);  // Adding a small tolerance for exact match

            console.log("Scroll Top: ", scrollTop);
            console.log("Document Height: ", documentHeight);
            console.log("Window Height: ", windowHeight);
            console.log("Scrolled to Bottom: ", scrolledToBottom);

            if (scrollTop > constantY) {
                sidebars[0].classList.add("sticky-panel");
                console.log("Added sticky-panel class");
            } else {
                sidebars[0].classList.remove("sticky-panel");
                console.log("Removed sticky-panel class");
            }
            

            if (scrolledToBottom) {
                sidebars[0].classList.add("move-up");
                console.log("Added move-up class");
            } else {
                sidebars[0].classList.remove("move-up");
                console.log("Removed move-up class");
            }
        };

        document.querySelectorAll('.scroll-link').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                const target = document.querySelector(this.getAttribute('href'));
                target.scrollIntoView({ behavior: 'smooth' });
            });
        });
        

    }, []);

    return (
        <div id="services-page" className='blog-page tutoring-page our-services'> 
            <Helmet>
                <title>Web Design Business Guide</title>
                <meta name="description" 
                      content="Free guide to starting and running a web design business in 2024"
                />
            </Helmet>
            <div className='services-top'>
                <div id="services-top-img-body" className='my-5'>
                    <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                </div>                
                <h1 className='services-header-title fade-in-up-fast'>Web Design Business Guide</h1>
            </div>
            <div className='services-top-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
       
            <div class="container" id="blog">
                <div class="row">
                    <div class="col-md-12">
                        <div class="white-box">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mobile-menu">
                        <div class="mobile-sidebar">
                            <div class="white-box">
                                <h2>Topics Covered:</h2>
                                <br/>
                                <p><a href="#part1" class="scroll-link">1. Before Registering Your Business</a></p>
                                <p><a href="#part2" class="scroll-link">2. Branding</a></p>
                                <p><a href="#part3" class="scroll-link">3. Registering Your Company</a></p>
                                <p><a href="#part4" class="scroll-link">4. Managing Your Business</a></p>
                                <p><a href="#part5" class="scroll-link">5. Doing the Work</a></p>
                                <p><a href="#part6" class="scroll-link">6. Getting Clients</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="content col-md-8">
                        <div class="white-box">
                        <section id="introduction">
                            <h1>How to Start and Succeed in a Web Design Business: A Complete 2024 Guide</h1>
                            <img src="assets/unablog.jpg"/>
                            <p>
                            Hello everyone! I’m Moreno Dizdarevic, the founder and CEO of Una Web Solutions.  After years of doing freelance projects on the side as I finished up college and worked at a software company, I quickly noticed that the only way to actually grow and stay legally safe is to incorporate and set up a legitimate company. On May 29th, 2023, I did just that when I turned my web design “side gig” into a legitimate business, an official LLC.
                                </p>
                                <p>
                                In this guide, I will go over everything I’ve learned about starting and succeeding with a web design business in 2024. I will go over everything from registering the business, to getting high-paying clients, taxes, and more! Nothing is guaranteed, but I hope some of these tips and tricks that have worked for me work for you too. Let’s get started!
                                </p>
                        </section>
                        <section id="part1">
                            <h2>Before Registering Your Business</h2>
                            <h3>Business Model</h3>
                            <p>Before even registering a web design business, you need to figure out a couple of things. First of all, your business model. Basically, how do you want to sell your services? This depends on your location, skill level, and startup capital.</p>
                            <h3>Startup Capital</h3>
                            <p>Startup capital is how much money you have saved up in the beginning to invest in your business. I honestly didn’t have that much, but the more you can save up, the better. Having around $1000-$2000 is good enough if you’re just beginning.</p>
                            <h3>Pricing Strategy</h3>
                            <p>If you’re just starting and don’t have a lot of capital or connections, I recommend starting with a fixed rate. “Fixed rate” means the client pays you one lump sum payment for your work. People don’t know or trust you enough to pay you hourly or with a monthly subscription. Start with a couple of brochure sites for $500-$600 to get your foot through the door. After a few projects, set up an hourly or subscription rate to get reliable income consistently.</p>
                            <p>Hourly and subscription rates are the key to getting reliable income with web design. It ensures that you always have money coming in. That way, you won’t be sitting there for months with no money. </p>
                            <h3>Down Payments</h3>
                            <p>Always have some kind of down payment! If you don’t, you might end up waiting months for any payment if the client takes a while to respond to emails or provide the content you need. This has happened to me before. I didn’t include a down payment for one project and the first payment I received from that client was nearly a month into working on the project.</p>
                        </section>
                        <section id="part2">
                            <h2>Branding</h2>
                            <h3>Business Identity</h3>
                            <p>The point of registering an LLC is so that the client isn’t working with just some guy; they’re working with a brand, a business identity separate from you as a person. This means you need to set up a clear color theme, logo, website, and social media.</p>
                            <h3>Choosing a Company Name</h3>
                            <p>The company name is HUGE. You can either:
                                <ul>
                                    <li>Target Your Local Area: Including something related to your area in the name is great. For example, if you’re in New York City, “Big Apple Web Design” is a good example.</li>
                                    <li>Personal Connection: Pick something more personal to you. My company is Una Web Solutions, named after the Una River in Bosnia. Plus, it’s only three letters, so it’s quick to spell and easy to remember.</li>
                                </ul>
                            </p>
                            <img src="assets/unariverguidepic.png"/>
                        </section>
                        <section id="part3">
                            <h2>Registering Your Company</h2>
                            <h3>LLC Registration</h3>
                            <p>If you’re in the US, register a Limited Liability Company (LLC). The benefit of an LLC is that it’s a separate legal entity from you, so your personal assets are not connected with your business assets. If you get sued, only your business assets are at risk, not your personal belongings. Each state and country works differently, so check online for specific requirements. The cost to file is different everywhere too, i.e. the filing fee in New York State is $200.</p>
                            <p>Here is a link to <a target="_blank" href="https://www.irs.gov/businesses/small-businesses-self-employed/starting-a-business">more resources</a> for starting a business in the US.</p>
                            <h3>Business Bank Account</h3>
                            <p>After registering your LLC, start a separate bank account for your business. Do not mix personal and business funds, as it will come back to bite you during tax season. Form an LLC and a separate bank account before starting any paid work.</p>
                        </section>
                        <section id="part4">
                            <h2>Managing Your Business</h2>
                            <h3>Contracts</h3>
                            <p>Never do anything without a contract. An LLC is your first level of protection, and a contract is your second level. Clearly state everything in the contract, including the work you’re doing, payment terms, timeline, scope, and ownership of content.</p> 
                            <p>Here is a link to a <a target="_blank" href="https://docs.google.com/document/d/1Ivzm1x3kt3qw6njkRNfRueI-xign7s-6wBUzu_DwPWM/edit?usp=sharing">contract template</a> that you can freely use and edit.</p>
                            <a target="_blank" href="https://docs.google.com/document/d/1Ivzm1x3kt3qw6njkRNfRueI-xign7s-6wBUzu_DwPWM/edit?usp=sharing"><img src="assets/contractguidepic.jpg"/></a>
                            <h3>Payment Processing</h3>
                            <p>You’ll need invoicing software to handle payments. I recommend <a target="_blank" href="https://squareup.com/us/en">Square Up</a> for its reliability, automatic transfers, and recurring payments. If you’re outside the US, <a target="_blank" href="https://www.hellobonsai.com/">Bonsai</a> might be a better option, since I’ve heard it is available in more countries. </p>
                            <h3>Taxes</h3>
                            <p>Taxes can be confusing, so hire an accountant as soon as possible to avoid mistakes. Track your expenses and profits meticulously. Apps like <a target="_blank" href="https://www.everlance.com/">Everlance</a> can help you track expenses, ensuring you don’t miss anything during tax time.</p>
                            <p>And trust me, you’re going to want to find as many expenses as possible during tax time. A web design business can’t declare all the expenses a restaurant can for example. So anything you can think of (subscriptions, Internet, electricity, gas mileage, etc.) should be tracked as an expense. </p>
                        </section>
                        <section id="part5">
                            <h2>Doing the Work</h2>
                            <h3>Web Design Process</h3>
                            <p>Web design involves making the visual layout of the website. Use a platform like <a target="_blank" href="https://www.figma.com/">Figma</a> to set up a web design file, allowing clients to make notes on what they like and don’t like. Once they approve the design, start the development. If you’re more into development, consider outsourcing design to a designer, either locally or online on platforms like <a target="_blank" href="https://www.fiverr.com/">Fiverr</a> or <a target="_blank" href="https://www.upwork.com/">Upwork</a>.</p>
                            <h3>Outsourcing</h3>
                            <p>Outsourcing is the secret to scaling up your business. By getting other people to do pieces of your business for you, that frees up your time to focus on growing the business or starting a new project. In the beginning, you’ll likely do everything yourself, but keep outsourcing in mind for the future to free up time for more clients or other projects.</p>
                        </section>
                        <section id="part6">
                            <h2>Getting Clients</h2>
                            <h3>Selling Solutions</h3>
                            <p><i>“How do I get clients?”</i> is the main question I hear and the main question that was in my head for years. The best piece of advice I’ve heard that completely changed the way I’ve gotten new (and higher-paying) clients is this: as a web design business owner, </p>
                            <img src="assets/sellingsolutions.png"/>
                            <p>Approaching someone and asking them “Hey, you want a website?” is going to end in an immediate “NO”. But by identifying problems businesses face and offering solutions, the client is more likely to see the value you can actually provide. This approach also leads to higher-paying clients since they know you’re actually helping to grow their business, not just putting some basic website up online.</p>
                            <p>For example, if there is a client who doesn't "need" a website since they're selling everything on Facebook. BUT, their clients have to go to some other platform and sign up so they can buy something, <i>that's a problem you can solve.</i></p>
                            <p>Or if there is a client who has a website which shows all of their products or resources, but the only thing they're able to put on there are some Canva flyers with no clickable buttons, <i>that's a problem you can solve.</i></p>
                            <h3>Networking</h3>
                            <p>Networking is by far one of my most successful ways I’ve gotten clients. Build free websites for friends’ businesses or local nonprofits. Join online groups, attend networking events, or simply start conversations with people you meet. Personal connections can lead to client recommendations.</p>
                            <h3>Social Media</h3>
                            <p>Social media can also be a powerful tool. If you have a larger following and like to post and engage with people, definitely share your business on your platforms to reach a broader audience. Videos can be especially effective for making sales pitches more personal.</p>
                            <h3>Other Methods</h3>
                            <p>
                                <ul>
                                    <li>Ads: If you have enough startup capital, consider ads on Facebook or Google.</li>
                                    <li>Teaching: If you like to teach people, you could get clients that way. This has gotten me multiple clients. I offer online tutoring sessions to teach people how to code or build a website for their business. There have been several times where the person in the middle of the session says “Hey, I don’t have the time and expertise to do this, can I just pay you to do it”? </li>
                                    <li>Cold Outreach: My least successful method. Most of the time, cold emails or calls are either ignored or the person sees you as a scammer. One way around this is with video. If you see a person you really want to be a client, send them a quick video where you just introduce yourself, mention an issue on their website, and a couple sentences where you discuss who you’ll fix it.</li>
                                </ul>
                            </p>
                        </section>
                        <section id="conclusion">
                            <h2>Conclusion</h2>
                            <p>There you have it folks! That is all I can think of that you will need to start and succeed with a web design business in 2024. Running a web design business in 2024 has been great for me. I absolutely love the flexibility and the ability to work from anywhere. I hope this guide helps you succeed in your web design business. If you have any questions about anything, you can email me at <a href="mailto:info@unawebsolutions.com">info@unawebsolutions.com</a>.</p>
                        </section>                        
                    </div>
                </div>
                <div class="col-md-4 desktop-menu">
                    <div class="sidebar">
                        <div class="white-box">
                            <h2>Topics Covered:</h2>
                            <br/>
                            <p><a href="#part1" class="scroll-link">1. Before Registering Your Business</a></p>
                            <p><a href="#part2" class="scroll-link">2. Branding</a></p>
                            <p><a href="#part3" class="scroll-link">3. Registering Your Company</a></p>
                            <p><a href="#part4" class="scroll-link">4. Managing Your Business</a></p>
                            <p><a href="#part5" class="scroll-link">5. Doing the Work</a></p>
                            <p><a href="#part6" class="scroll-link">6. Getting Clients</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
            <div className="bottom-wave">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            {/* <Popup/> */}
        </div>
            
                    
    );
}

export default WebDesignBusinessGuide; 