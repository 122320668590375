import React from 'react';
import { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import JotformEmbed from 'react-jotform-embed';

const Contact = () => {

    useEffect(() => {            
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }    
        
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
              });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }
         });

         var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

         fadeElementsLoad.forEach(function(loadelement) {
     
             loadelement.style.opacity = 1;
             loadelement.style.visibility = 'visible';
             loadelement.style.webkitTransform = 'translateY(0px)';
             loadelement.style.transform = 'translateY(0px)';
         });

        window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
                fadeElements.forEach(function(element) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(0px)';
                    element.style.transform = 'translateY(0px)';
                });
            }
        });

    }, []);

    return (
        <div id="contact-page" className='get-in-touch'> 
            <Helmet>
                <title>Let's Talk!</title>
                <meta name="description" 
                      content="We look forward to hearing from you about any way that we can help."
                      data-react-helmet="true"
                />
            </Helmet>
            <div className='services-top'>
                <div id="services-top-img-body" className='my-5'>
                    <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                </div>                
                <h1 className='services-header-title fade-in-up-fast'>Get In Touch</h1>
            </div>
            <div className='services-top-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <div id="una-web-contact">UNA WEB SOLUTIONS</div>
            <div id='contact-wrapper' className='white-top contact-body'>
                <div className="container">
                    <br/>
                    <div className="my-5 py-3 justify-content-center">
                        <div className='contact'>
                            <h1 className='contact-txt'>Let's Talk!</h1>
                            <br/>
                            <form className="contact-form w-100" 
                                name="Contact" 
                                method="POST" 
                                data-netlify="true" 
                                netlify-honeypot="bot-field"
                                id="contact"
                                >
                                <div>  
                                    <input type="hidden" name="form-name" value="Contact" />
                                    <input type="hidden" name="subject" 
                                    value="Form Submission from Website" />
                                    <div className="form-group">
                                                <label className='form-label'>Full Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="first_name"
                                                    name="First_name"
                                                />
                                                <br/> 
                                        <label className='form-label'>Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="Email"
                                        />
                                        <br/>
                                        <label className='form-label'>Phone Number</label>
                                        <input 
                                            type="tel" 
                                            className="form-control"
                                            id="phone" 
                                            name="Phone"
                                        />
                                    </div>                                                                               
                                </div>
                                <div>
                                    <label className='form-label'>Message</label>
                                    <textarea
                                        className="form-control"
                                        id="FormControlTextarea"
                                        rows="10"
                                        name="Message"
                                    ></textarea>
                                </div>
                                <br/>
                                <button type="submit" id="contact-us-submit-btn" className="main-btn-white">Submit</button> 
                            </form>
                       </div>
                    </div>                               
                </div>
            </div>
            <div id='bottom-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
        </div>
    );
}

export default Contact;