import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import Popup from './Popup';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const Home = () => {

    // Fixes bug of refresh not loading on home sometimes
    useEffect(() => {
        window.history.scrollRestoration = 'manual'
      }, []);

 
    useEffect(() => {            
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");

        const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'white';
              });
        

            if (window.innerWidth < 700) {
                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'black';
                }   );
            }
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
              });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }

        });

        var heroVideo = document.getElementById("UNA_Video");
            
        heroVideo.oncanplaythrough = function() {
            heroVideo.muted = true;
            heroVideo.setAttribute("playsinline", "");
            heroVideo.play();
        }

        var fadeElementsLoad = document.querySelectorAll('.fade-in-up-load');

        fadeElementsLoad.forEach(function(loadelement) {
    
            loadelement.style.opacity = 1;
            loadelement.style.visibility = 'visible';
            loadelement.style.webkitTransform = 'translateY(0px)';
            loadelement.style.transform = 'translateY(0px)';
        });

        var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

        fadeElementsLoad.forEach(function(loadelement) {
    
            loadelement.style.opacity = 1;
            loadelement.style.visibility = 'visible';
            loadelement.style.webkitTransform = 'translateY(0px)';
            loadelement.style.transform = 'translateY(0px)';
        });

            window.addEventListener('scroll', function() {

                // Function to check if an element is in the viewport
                function isScrolledIntoView(element) {
                    var rect = element.getBoundingClientRect();
                    return (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                    );
                }
            
                    // Get all elements with the class 'fade-in-up'
                    var fadeElements = document.querySelectorAll('.fade-in-up');
                
                    fadeElements.forEach(function(element) {
                        var point = "0px", move = "50px";
                
                        if (isScrolledIntoView(element)) {
                            element.style.opacity = 1;
                            element.style.visibility = 'visible';
                            element.style.webkitTransform = 'translateY(' + point + ')';
                            element.style.transform = 'translateY(' + point + ')';
                        } else {
                            element.style.opacity = 0;
                            element.style.visibility = 'hidden';
                            element.style.webkitTransform = 'translateY(' + move + ')';
                            element.style.transform = 'translateY(' + move + ')';
                        }
                    });

                    if (window.innerWidth < 600) {
                        fadeElements.forEach(function(element) {
                            element.style.opacity = 1;
                            element.style.visibility = 'visible';
                            element.style.webkitTransform = 'translateY(0px)';
                            element.style.transform = 'translateY(0px)';
                        });
                    }
            });

    }, []);


    return ( 
        <div id="hero-page">
            <Helmet>
                <title>Hand-Coded Web Design & Development</title>
                <meta name="description" 
                      content="Custom Web Design, Hand-Coded Development, 1-on-1 Tutoring & Consulting."
                />
            </Helmet>
            <section id="home-hero-section">
                <div id="home-hero-image-body">
                    <video id="UNA_Video" playsinline autoplay muted loop poster='assets/una_bg_img.webp'>
                        <source src="assets/UNA_hero_vid.mp4" type="video/mp4"/>
                    </video>
                    <img id="UNA_Image" src='assets/una_bg_img.webp'/>
                    <div id="home-hero-content">
                        <div className="home-hero-content">
                            <div className="home-hero-content-text slide-effect">
                                <h1 className='container hero-title fade-in-up-fast'>DESIGN. CODE. EDUCATE.</h1>
                            </div>
                        </div>
                        <div className='container home-btn-body'>
                            <a href='/web-development'><button type="submit" className="main-btn-white fade-in-up-load">Dive Right In<i className='fa fa-arrow-right'></i></button></a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-services-section">
                <div className='row'>
                    <div className='col-lg-6'>
                    </div>
                    <div className='col-lg-6'>
                        <div className='before-after'>
                            <ReactCompareSlider className='before-after-img'
                                itemOne={<ReactCompareSliderImage alt='before image' src="assets/ochc-before.webp" />}
                                itemTwo={<ReactCompareSliderImage alt='before image' src="assets/ochc-after.webp"/>}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <br/>
                <section id='services-section'>
                            <div id='services-wrapper'>
                            <div className="container">
                                <h1 className="home-about-header-text fade-in-up">MY SERVICES</h1>
                                <h3 id='services-header-txt' className='fade-in-up'>Your Responsibility is <span>My</span> Responsibility.</h3>
                                <div id='services-first-txt' className='fade-in-up'>
                                    <br/>
                                    <p>Between translating your creative ideas into a design, developing a website with SEO and 
                                    responsiveness in mind, and dealing with secure hosting, maintenance issues, 
                                    learning to code... it's all <span id='services-first-txt-blue'>a lot</span>.  
                                    <br/><br/>
                                    I work directly with business owners, nonprofit directors, entrepreneurs, executives,
                                    future web designers and developers - offering peace of mind to all.</p>
                                    <br/>
                                </div>
                                <br/><br/>
                                        <div className="row">
                                            <div className="col">
                                                <div id='home-services-card-id' className='card home-services-card container'>
                                                    <div id='home-img-body'>
                                                        <img id='home-serviceimgid' className="card-img fade-in-up" src="../assets/servicepics/collaborative.svg" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h4 className="card-title fade-in-up">Collaborative Design</h4>
                                                        <p className="card-text fade-in-up">I will work with you every step of the way to bring your creative ideas for a website to life - exactly as envisioned. If needed, an in-house graphic designer can create a logo too! 
                                                            <span id='read-more-btn'>
                                                                <br/>
                                                                <a href='/web-development'>Read more</a>
                                                                <br/><br/>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div id='home-services-card-id' className='card home-services-card container'>
                                                    <div id='home-img-body'>
                                                        <img id='home-serviceimgid' className="card-img fade-in-up" src="../assets/servicepics/custom.svg" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h4 className="card-title fade-in-up">Custom Code</h4>
                                                        <p className="card-text fade-in-up">All parts of the website will be custom coded, line by line, to create an entirely unique online experience. No cookie-cutter website builders. No annoying plugins in need of constant updating.  
                                                        <span id='read-more-btn'>
                                                            <br/>
                                                            <a href='/web-development'>Read more</a>
                                                            <br/><br/>
                                                        </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div id='home-services-card-id' className='card home-services-card container'>
                                                    <div id='home-img-body'>
                                                        <img id='home-serviceimgid' className="card-img fade-in-up" src="../assets/servicepics/responsive.svg" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h4 className="card-title fade-in-up">Fully Responsive</h4>
                                                        <p className="card-text fade-in-up">Did you know that 60% of all web traffic comes through mobile phones? For this reason, it is very important to create a site that remains visually appealing, regardless of device or screen size. 
                                                        <span id='read-more-btn'>
                                                            <br/>
                                                            <a href='/web-development'>Read more</a>
                                                            <br/><br/>
                                                        </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="row">
                                            <div className="col">
                                                <div id='home-services-card-id' className='card home-services-card container'>
                                                    <div id='home-img-body'>
                                                        <img id='home-serviceimgid' className="card-img fade-in-up" src="../assets/servicepics/seo.svg" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h4 className="card-title fade-in-up">Search Engine Optimization</h4>
                                                        <p className="card-text fade-in-up">Having a SEO-friendly website increases your site's digital presence and grows your base. We apply multiple techniques to create a site as SEO-friendly as possible. 
                                                        <span id='read-more-btn'>
                                                            <br/>
                                                            <a href='/web-development'>Read more</a>
                                                            <br/><br/>
                                                        </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div id='home-services-card-id' className='card home-services-card container'>
                                                    <div id='home-img-body'>
                                                        <img id='home-serviceimgid' className="card-img fade-in-up" src="../assets/servicepics/server.svg" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h4 className="card-title fade-in-up">Hosting Services</h4>
                                                        <p className="card-text fade-in-up">As part of my services, I offer the option to register the domain and securely host your site, eliminating the hassle of having to deal with any potential server issues down the line. 
                                                        <span id='read-more-btn'>
                                                            <br/>
                                                            <a href='/web-development'>Read more</a>
                                                            <br/><br/>
                                                        </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div id='home-services-card-id' className='card home-services-card container'>
                                                    <div id='home-img-body'>
                                                        <img id='home-serviceimgid' className="card-img fade-in-up" src="../assets/servicepics/support.svg" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h4 className="card-title fade-in-up">Continued Support</h4>
                                                        <p className="card-text fade-in-up">I view all clients as partners, as such, I offer the option of unlimited edits even after the site is completed and continued communication for any issues related to the site.
                                                        <span id='read-more-btn'>
                                                            <br/>
                                                            <a href='/web-development'>Read more</a>
                                                            <br/><br/>
                                                        </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                </section>
                <section id="home-bg-section">
                    <div className="home-wave-body" id='home-wave'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#FCFCFF" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                    </div>
                    <div id="home-bg-image-body">
                            <img src="assets/coding-home.webp"/>
                        <div id="home-bg-content">
                            <div className="home-bg-content">
                                <div className="home-bg-content-text">
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <h1 className="home-about-header-text fade-in-up">MY APPROACH</h1>
                                            <h3 className='fade-in-up'>Hands-On. Hand-Coded.</h3>
                                            <p className='fade-in-up'>I approach each project with
                                                quality over quantity in mind. For this reason, 
                                                behind the scenes of each website is not template junk,
                                                but pure code, written <span>line by line.</span>
                                            </p>
                                            <p className='fade-in-up'>
                                                My approach is unique because I'm involved in every 
                                                step of the way; from <i>design, development, hosting, and maintenance. </i>
                                            </p>
                                        </div>
                                        <div className='col-lg-4'>
                                            <button type="submit" className="main-btn-white"><a href='/contact'>Get a Free Quote</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-wave-body" id='home-bottom-wave'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#8fcaf7" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                    </div>
                </section>
                <section id="home-portfolio">
                        <div id="portfolio-section-2" className='row'>
                            <div className='col-lg-6'>
                                <div className='portfolio-text-body'>
                                    <h1 className="home-about-header-text fade-in-up">My work</h1>
                                    <h3 id='home-portfolio-text' className='portfolio-txt container fade-in-up'>Past Clients </h3>
                                    <p className='container portfolio-text fade-in-up'>
                                    I have had the honor of working with a range of clients, from business owners to nonprofit directors. Check out some of my hand-coded, fully-responsive case studies. </p>
                                </div>
                                <button type="submit" className="main-btn-white fade-in-up"><a href='/work'>View all work</a></button>
                                <div id="left-portfolio" className='row'>
                                    <div className='col-lg-6'>
                                        <div className='cny_drones floating'>
                                        <a href="https://www.midtownutica.org/" target="_blank" rel="noreferrer"><img src="../assets/mucchome.webp"/></a>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='cny_drones floating'>
                                        <a href="https://idajewelryshop.com/" target="_blank" rel="noreferrer"><img src="../assets/idahome.webp"/></a>
                                        </div>                         
                                    </div>
                                    <div className='mobile-work col-lg-6'>
                                        <div className='cny_drones floating'>
                                            <a href="https://cnydroneservices.com/" target="_blank" rel="noreferrer"><img src="../assets/CNY_Drones.webp"/></a>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div id="work-large" className='col-lg-6'>
                                <div className='cny_drones'>
                                    <a href="https://cnydroneservices.com/" target="_blank" rel="noreferrer"><img src="../assets/CNY_Drones.webp"/></a>
                                </div>
                            </div>
                        </div>
                    </section>
                <section className='about-us' id='about-section'>
                    <div id='about-wrapper' className="home-about container">
                            <div className='row'>
                                <div id="home-about-img" className='col-lg-6 floating'>
                                    <img src="../assets/mewithhoodiebw.jpeg"/>
                                </div>
                                <div id="home-about-text" className='col-lg-6 about-txt'>
                                    <h1 className="home-about-header-text fade-in-up">About Me</h1>
                                    <h3 id='about-home-our-story' className='fade-in-up'>Web Design with a Mission</h3>
                                    <p className='fade-in-up'>Una Web Solutions is a web design and development 
                                        company started to empower small businesses and 
                                        nonprofits to better serve their local community. 
                                        At the core of Una is this mission of community
                                        action by solving web issues and bringing your 
                                        creative ideas for a website to life in an affordable
                                        way. 
                                    </p>
                                    <br/>
                                    <button type="submit" className="main-btn-white"><a href='/about'>More About Me</a></button>
                                </div>
                            </div>
                        
                    </div>
                        <svg class="about-fish fish" id="fish">
                        <path
                            id="fish2"
                            d="m 172.04828,20.913839 c 0.0489,-0.444179 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.475049 -1.4826,-0.932948 -2.2149,-1.401138 -1.6035,-1.028129 -3.29018,-1.969653 -4.89798,-3.079244 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.1509392 -2.27235,-0.286401 -4.81223,-0.168925 -6.72186,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.44486897 -2.77227,-1.44486897 0,0 1.30939,3.55000597 1.60951,4.26429497 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.0418502 2.13772,0.8129002 2.26463,1.7827212 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082841 -11.56925,0.884071 -4.3046,-1.38313 -7.37269,-4.129669 -10.46566,-7.2354952 1.43801,6.7252892 5.4382,10.6028562 5.6157,11.4226162 0.18607,0.905509 -0.45961,1.091584 -1.04099,1.682394 -1.28967,1.265655 -6.91566,7.731125 -6.93366,9.781383 1.61379,-0.247815 3.56115,-1.660957 4.9803,-2.485862 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932949 0.0695,0.932949 -0.30784,1.137031 -0.18436,1.527189 0.22638,0.746016 1.44144,1.465449 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.07136 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.644,1.353976 8.56032,1.353976 3.50799,0.0094 12.726,0.258104 19.55505,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.577091 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925922 z"
                            />
                        <path                            
                            d="m 234.99441,42.953992 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89798,-3.079245 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.150939 -2.27235,-0.286401 -4.81223,-0.168925 -6.72186,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.444869 -2.77227,-1.444869 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.04185 2.13772,0.8129 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 -4.3046,-1.383131 -7.37269,-4.12967 -10.46566,-7.235496 1.43801,6.725289 5.4382,10.602857 5.6157,11.422617 0.18607,0.905508 -0.45961,1.091583 -1.04099,1.682394 -1.28967,1.265654 -6.91566,7.731125 -6.93366,9.781382 1.61379,-0.247815 3.56115,-1.660957 4.9803,-2.485862 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527188 0.22638,0.746016 1.44144,1.46545 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.071359 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.644,1.353976 8.56032,1.353976 3.50799,0.0094 12.726,0.258104 19.55505,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.57709 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925921 z"
                            id="fish3" />
                        <path
                            id="fish6"
                            d="m 200.07076,80.737109 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89798,-3.079245 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.150939 -2.27235,-0.286401 -4.81223,-0.168925 -6.72186,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.444869 -2.77227,-1.444869 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.04185 2.13772,0.8129 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 -4.3046,-1.383131 -7.37269,-4.12967 -10.46566,-7.235496 1.43801,6.725289 5.4382,10.602857 5.6157,11.422617 0.18607,0.905508 -0.45961,1.091583 -1.04099,1.682394 -1.28967,1.265654 -6.91566,7.731125 -6.93366,9.781382 1.61379,-0.247815 3.56115,-1.660957 4.9803,-2.485862 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527188 0.22638,0.746016 1.44144,1.46545 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.071359 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.644,1.353976 8.56032,1.353976 3.50799,0.0094 12.726,0.258104 19.55505,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.57709 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925921 z"
                            />
                        <path
                            d="m 77.275623,89.018799 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89798,-3.079245 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.150939 -2.272347,-0.286401 -4.812227,-0.168925 -6.721857,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.444869 -2.77227,-1.444869 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.04185 2.13772,0.8129 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 -4.3046,-1.383131 -7.37269,-4.12967 -10.46566,-7.235496 1.43801,6.725289 5.4382,10.602857 5.6157,11.422617 0.18607,0.905508 -0.45961,1.091583 -1.04099,1.682394 -1.28967,1.265654 -6.9156603,7.731122 -6.9336603,9.781382 1.6137903,-0.24782 3.5611503,-1.66096 4.9803003,-2.48586 1.58035,-0.90551 7.60593,-5.37303 9.29347,-6.065025 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527183 0.22638,0.74602 1.44144,1.46546 2.02282,1.98509 1.50918,1.29224 3.21044,2.42841 4.27373,4.15625 1.49203,2.40183 1.55805,4.999171 1.98251,7.677111 0.99469,-0.11148 2.0091,-2.17545 2.55961,-2.99264 0.51278,-0.7726 2.38639,-4.071361 3.09725,-4.275441 0.67227,-0.20408 2.75511,0.95867 3.50284,1.18076 2.85973,0.84806 5.644,1.35398 8.560317,1.35398 3.50799,0.009 12.726,0.2581 19.55505,-4.80023 0.75545,-0.56766 2.55703,-2.7311 2.55703,-2.7311 0,0 -0.37644,-0.57709 -1.04785,-0.79061 0.89779,-0.58481 1.8659,-1.211632 1.94993,-1.92592 z"
                            id="fish4" />
                        <path
                            id="fish5"
                            d="m 127.65312,60.900973 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89799,-3.079245 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.150939 -2.27235,-0.286401 -4.812228,-0.168925 -6.721858,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.444869 -2.77227,-1.444869 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.04185 2.13772,0.8129 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 -4.3046,-1.383131 -7.37269,-4.12967 -10.46566,-7.235496 1.43801,6.725289 5.4382,10.602857 5.6157,11.422617 0.18607,0.905508 -0.45961,1.091583 -1.04099,1.682394 -1.28967,1.265654 -6.91566,7.731125 -6.93366,9.781382 1.61379,-0.247815 3.56115,-1.660957 4.9803,-2.485862 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527188 0.22638,0.746016 1.44144,1.46545 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.071359 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.643998,1.353976 8.560318,1.353976 3.50799,0.0094 12.726,0.258104 19.55506,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.57709 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925921 z"
                            />
                        <path
                            d="m 68.19699,20.522295 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89798,-3.079245 C 55.39553,11.384887 49.845,10.221274 44.30305,9.4752582 42.0307,9.1888572 39.49082,9.3063332 37.58119,7.900907 36.09945,6.819613 33.53126,3.072384 30.71613,1.444869 30.22993,1.154181 27.94386,0 27.94386,0 c 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.0418502 2.13772,0.8129002 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 C 8.52001,12.607667 5.45192,9.8611282 2.35895,6.755302 3.79696,13.480591 7.79715,17.358159 7.97465,18.177919 8.16072,19.083427 7.51504,19.269502 6.93366,19.860313 5.64399,21.125967 0.018,27.591438 0,29.641695 1.61379,29.39388 3.56115,27.980738 4.9803,27.155833 c 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527188 0.22638,0.746016 1.44144,1.46545 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.071359 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.644,1.353976 8.56032,1.353976 3.50799,0.0094 12.726,0.258104 19.55505,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.57709 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925921 z"
                            id="fish1" />
                        </svg>
                </section>
                <div id="wave_bg_2" >
                    <img src='assets/wave_bg.svg'/>
                </div>
                <section id='clients-section'>
                    <div id='services-wrapper'>
                        <div id='whatclients'>
                                <h1 id="home-test-header-text" className='fade-in-up'>Testimonials</h1>
                                <h3 className='tests-question-txt fade-in-up'>What My Clients Say</h3>
                                <p id='whatclients-txt' className='fade-in-up'>
                                I strive to work with you every step of the way to see your small business or nonprofit succeed with a unique website.
                                </p>
                            </div>
                            <div className="container">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <div class="outerdiv floating">
                                            <div class="innerdiv">
                                            <div class="reviewbody eachdiv">
                                                <div class="userdetails">
                                                    <div class="detbox">
                                                        <div className='row'>
                                                            <div className='col-lg-2'>
                                                                <div class="imgbox">
                                                                    <img src="assets/idabloglogo_una.png" alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <p class="name">Ida D.</p>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <p class="designation">Ida Jewelry LLC</p>
                                                        <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div class="review">
                                                <h4>
                                                Very satisfied! Moreno is great to work with because he does so much (hosting, updates, SEO stuff). It’s like he’s an employee :) My business grew 100% in the last couple months since Moreno made the new website! I really recommend Una Web Solutions to everyone! 
                                                </h4>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div class="outerdiv floating">
                                            <div class="innerdiv">
                                            <div class="reviewbody eachdiv">
                                                <div class="userdetails">
                                                    <div class="detbox">
                                                        <div className='row'>
                                                            <div className='col-lg-2'>
                                                                <div class="imgbox">
                                                                    <img src="assets/MUCC_LOGO_una.png" alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <p class="name">Kathryn S.</p>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <p class="designation">Midtown Utica Community Center</p>
                                                        <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div class="review">
                                                <h4>
                                                The owner is skillful, responsible, and the ultimate professional. He did a wonderful job helping us with our information design challenges. I would recommend to anyone!
                                                </h4>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div class="outerdiv floating">
                                            <div class="innerdiv">
                                            <div class="reviewbody long-review eachdiv">
                                                <div class="userdetails">
                                                    <div class="detbox">
                                                        <div className='row'>
                                                            <div className='col-lg-2'>
                                                                <div class="imgbox">
                                                                    <img src="assets/edslogos_logo.svg" alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <p class="name">Ed D.</p>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <p class="designation">Ed's Logos</p>
                                                        <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div class="review">
                                                    <h4>
                                                    If you want a great looking and modern website that looks professional then contact Moreno. 
                                                    He made my website look like a million bucks and best of all he's not crazy expensive. 
                                                    He even responded to me in the middle of the night when I changed my mind on a specific design.
                                                    Honestly, give him a call. I can't recommend him enough.
                                                    </h4>
                                                </div>
                                            </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>     
                            </div> 
                    </div>
                </section>
                {/* <div className='home-bottom-wave'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#2e9aed" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                </div> */}
                <section id='contact-section'>
                    <div id='contact-wrapper' className='contact-body'>
                        <br/><br/><br/><br/>
                        <div className='fishes'>
                        <svg class="fish" id="fish">
                        <path
                            id="fish2"
                            d="m 172.04828,20.913839 c 0.0489,-0.444179 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.475049 -1.4826,-0.932948 -2.2149,-1.401138 -1.6035,-1.028129 -3.29018,-1.969653 -4.89798,-3.079244 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.1509392 -2.27235,-0.286401 -4.81223,-0.168925 -6.72186,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.44486897 -2.77227,-1.44486897 0,0 1.30939,3.55000597 1.60951,4.26429497 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.0418502 2.13772,0.8129002 2.26463,1.7827212 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082841 -11.56925,0.884071 -4.3046,-1.38313 -7.37269,-4.129669 -10.46566,-7.2354952 1.43801,6.7252892 5.4382,10.6028562 5.6157,11.4226162 0.18607,0.905509 -0.45961,1.091584 -1.04099,1.682394 -1.28967,1.265655 -6.91566,7.731125 -6.93366,9.781383 1.61379,-0.247815 3.56115,-1.660957 4.9803,-2.485862 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932949 0.0695,0.932949 -0.30784,1.137031 -0.18436,1.527189 0.22638,0.746016 1.44144,1.465449 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.07136 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.644,1.353976 8.56032,1.353976 3.50799,0.0094 12.726,0.258104 19.55505,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.577091 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925922 z"
                            />
                        <path                            
                            d="m 234.99441,42.953992 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89798,-3.079245 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.150939 -2.27235,-0.286401 -4.81223,-0.168925 -6.72186,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.444869 -2.77227,-1.444869 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.04185 2.13772,0.8129 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 -4.3046,-1.383131 -7.37269,-4.12967 -10.46566,-7.235496 1.43801,6.725289 5.4382,10.602857 5.6157,11.422617 0.18607,0.905508 -0.45961,1.091583 -1.04099,1.682394 -1.28967,1.265654 -6.91566,7.731125 -6.93366,9.781382 1.61379,-0.247815 3.56115,-1.660957 4.9803,-2.485862 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527188 0.22638,0.746016 1.44144,1.46545 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.071359 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.644,1.353976 8.56032,1.353976 3.50799,0.0094 12.726,0.258104 19.55505,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.57709 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925921 z"
                            id="fish3" />
                        <path
                            id="fish6"
                            d="m 200.07076,80.737109 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89798,-3.079245 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.150939 -2.27235,-0.286401 -4.81223,-0.168925 -6.72186,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.444869 -2.77227,-1.444869 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.04185 2.13772,0.8129 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 -4.3046,-1.383131 -7.37269,-4.12967 -10.46566,-7.235496 1.43801,6.725289 5.4382,10.602857 5.6157,11.422617 0.18607,0.905508 -0.45961,1.091583 -1.04099,1.682394 -1.28967,1.265654 -6.91566,7.731125 -6.93366,9.781382 1.61379,-0.247815 3.56115,-1.660957 4.9803,-2.485862 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527188 0.22638,0.746016 1.44144,1.46545 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.071359 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.644,1.353976 8.56032,1.353976 3.50799,0.0094 12.726,0.258104 19.55505,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.57709 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925921 z"
                            />
                        <path
                            d="m 77.275623,89.018799 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89798,-3.079245 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.150939 -2.272347,-0.286401 -4.812227,-0.168925 -6.721857,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.444869 -2.77227,-1.444869 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.04185 2.13772,0.8129 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 -4.3046,-1.383131 -7.37269,-4.12967 -10.46566,-7.235496 1.43801,6.725289 5.4382,10.602857 5.6157,11.422617 0.18607,0.905508 -0.45961,1.091583 -1.04099,1.682394 -1.28967,1.265654 -6.9156603,7.731122 -6.9336603,9.781382 1.6137903,-0.24782 3.5611503,-1.66096 4.9803003,-2.48586 1.58035,-0.90551 7.60593,-5.37303 9.29347,-6.065025 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527183 0.22638,0.74602 1.44144,1.46546 2.02282,1.98509 1.50918,1.29224 3.21044,2.42841 4.27373,4.15625 1.49203,2.40183 1.55805,4.999171 1.98251,7.677111 0.99469,-0.11148 2.0091,-2.17545 2.55961,-2.99264 0.51278,-0.7726 2.38639,-4.071361 3.09725,-4.275441 0.67227,-0.20408 2.75511,0.95867 3.50284,1.18076 2.85973,0.84806 5.644,1.35398 8.560317,1.35398 3.50799,0.009 12.726,0.2581 19.55505,-4.80023 0.75545,-0.56766 2.55703,-2.7311 2.55703,-2.7311 0,0 -0.37644,-0.57709 -1.04785,-0.79061 0.89779,-0.58481 1.8659,-1.211632 1.94993,-1.92592 z"
                            id="fish4" />
                        <path
                            id="fish5"
                            d="m 127.65312,60.900973 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89799,-3.079245 -4.67074,-3.24131 -10.22127,-4.404923 -15.76322,-5.150939 -2.27235,-0.286401 -4.812228,-0.168925 -6.721858,-1.574351 -1.48174,-1.081294 -4.04993,-4.828523 -6.86506,-6.456038 -0.4862,-0.290688 -2.77227,-1.444869 -2.77227,-1.444869 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.04185 2.13772,0.8129 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 -4.3046,-1.383131 -7.37269,-4.12967 -10.46566,-7.235496 1.43801,6.725289 5.4382,10.602857 5.6157,11.422617 0.18607,0.905508 -0.45961,1.091583 -1.04099,1.682394 -1.28967,1.265654 -6.91566,7.731125 -6.93366,9.781382 1.61379,-0.247815 3.56115,-1.660957 4.9803,-2.485862 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527188 0.22638,0.746016 1.44144,1.46545 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.071359 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.643998,1.353976 8.560318,1.353976 3.50799,0.0094 12.726,0.258104 19.55506,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.57709 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925921 z"
                            />
                        <path
                            d="m 68.19699,20.522295 c 0.0489,-0.44418 -0.2178,-0.896934 -1.01784,-1.415715 -0.72801,-0.47505 -1.4826,-0.932949 -2.2149,-1.401138 -1.6035,-1.02813 -3.29018,-1.969653 -4.89798,-3.079245 C 55.39553,11.384887 49.845,10.221274 44.30305,9.4752582 42.0307,9.1888572 39.49082,9.3063332 37.58119,7.900907 36.09945,6.819613 33.53126,3.072384 30.71613,1.444869 30.22993,1.154181 27.94386,0 27.94386,0 c 0,0 1.30939,3.550006 1.60951,4.264295 0.69542,1.644664 -0.38158,3.063809 -0.83262,4.642447 -0.29069,1.0418502 2.13772,0.8129002 2.26463,1.782721 0.18179,1.432007 -4.15197,1.936211 -6.59152,2.417263 -3.65634,0.715146 -7.91635,2.082842 -11.56925,0.884072 C 8.52001,12.607667 5.45192,9.8611282 2.35895,6.755302 3.79696,13.480591 7.79715,17.358159 7.97465,18.177919 8.16072,19.083427 7.51504,19.269502 6.93366,19.860313 5.64399,21.125967 0.018,27.591438 0,29.641695 1.61379,29.39388 3.56115,27.980738 4.9803,27.155833 c 1.58035,-0.905509 7.60593,-5.373029 9.29347,-6.065023 0.38587,-0.160351 5.0549,-1.531476 5.09434,-0.932948 0.0695,0.932948 -0.30784,1.137031 -0.18436,1.527188 0.22638,0.746016 1.44144,1.46545 2.02282,1.985088 1.50918,1.292237 3.21044,2.42841 4.27373,4.156252 1.49203,2.401827 1.55805,4.999163 1.98251,7.677102 0.99469,-0.111473 2.0091,-2.17545 2.55961,-2.992638 0.51278,-0.772598 2.38639,-4.071359 3.09725,-4.275442 0.67227,-0.204082 2.75511,0.958673 3.50284,1.180763 2.85973,0.848057 5.644,1.353976 8.56032,1.353976 3.50799,0.0094 12.726,0.258104 19.55505,-4.800226 0.75545,-0.567658 2.55703,-2.731104 2.55703,-2.731104 0,0 -0.37644,-0.57709 -1.04785,-0.790605 0.89779,-0.584808 1.8659,-1.211633 1.94993,-1.925921 z"
                            id="fish1" />
                        </svg>
                    </div>
                        <div>
                            <h3 id='contactTxtDark' className='home-contact-txt fade-in-up'>Let's Create Something </h3>
                            <h3 id='contactTxtDark' className='home-contact-txt fade-in-up'>...Together </h3>
                            <div className="contact-btn-home py-3">
                            <button type="submit" className="main-btn-white"><a href='/contact'>Get Started</a></button>
                            </div>
                        </div>
                        <br/>
                    </div>
                </section>
                <Popup/>
            </div>
    );
}

export default Home;