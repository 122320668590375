import React from 'react';
import { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import JotformEmbed from 'react-jotform-embed';

const Reviews = () => {

    useEffect(() => {            
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }    
        
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
              });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }
         });

         var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

         fadeElementsLoad.forEach(function(loadelement) {
     
             loadelement.style.opacity = 1;
             loadelement.style.visibility = 'visible';
             loadelement.style.webkitTransform = 'translateY(0px)';
             loadelement.style.transform = 'translateY(0px)';
         });

        window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
                fadeElements.forEach(function(element) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(0px)';
                    element.style.transform = 'translateY(0px)';
                });
            }
        });

    }, []);

    return (
        <div id="reviews-page" className='get-in-touch'> 
            <Helmet>
                <title>Reviews</title>
                <meta name="description" 
                      content="We look forward to hearing from you about any way that we can help."
                      data-react-helmet="true"
                />
            </Helmet>
            <div className='services-top'>
                <div id="services-top-img-body" className='my-5'>
                    <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                </div>                
                <h1 className='services-header-title fade-in-up-fast'>Client Testimonials</h1>
            </div>
            <div className='services-top-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <section className='reviews-section'>
                {/* <div className="pricing-header">
                    <br/>    
                    <h1 className='portfolio-txt'>Affordable, flexible pricing</h1>
                    <p>Una offers peace of mind for businesses and nonprofits, without having to worry about hiring a full-time employee.
                        Below are our standard pricing plans, but we approach each project with a unique 
                        offer. <a href="/contact">Contact us </a> for a free quote.
                    </p>
                </div> */}
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div class="outerdiv">
                                <div class="innerdiv">
                                <div class="reviewbody eachdiv">
                                    <div class="userdetails">
                                        <div class="detbox">
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <div class="imgbox">
                                                        <img src="assets/idabloglogo_una.png" alt=""/>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <p class="name">Ida D.</p>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="designation">Ida Jewelry LLC</p>
                                            <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="review">
                                    <h4>
                                    Very satisfied! Moreno is great to work with because he does so much (hosting, updates, SEO stuff). It’s like he’s an employee :) My business grew 100% in the last couple months since Moreno made the new website! I really recommend Una Web Solutions to everyone! 
                                    </h4>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="outerdiv">
                                <div class="innerdiv">
                                <div class="reviewbody eachdiv">
                                    <div class="userdetails">
                                        <div class="detbox">
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <div class="imgbox">
                                                        <img src="assets/muccbots_logo_una.png" alt=""/>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <p class="name">Serr W.</p>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="designation">MUCCbots Robotics Team</p>
                                            <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="review">
                                    <h4>
                                    As a mentor of MUCCbots, I am extremely satisfied with the custom-coded website. It has provided us with a unique and tailored solution that perfectly meets our needs, allowing us to effectively showcase our team's capabilities and accomplishments
                                    </h4>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="outerdiv">
                                <div class="innerdiv">
                                <div class="reviewbody long-review eachdiv">
                                    <div class="userdetails">
                                        <div class="detbox">
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <div class="imgbox">
                                                        <img src="assets/edslogos_logo.svg" alt=""/>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <p class="name">Ed D.</p>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="designation">Ed's Logos</p>
                                            <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="review">
                                        <h4>
                                        If you want a great looking and modern website that looks professional then contact Moreno. 
                                        He made my website look like a million bucks and best of all he's not crazy expensive. 
                                        He even responded to me in the middle of the night when I changed my mind on a specific design.
                                        Honestly, give him a call. I can't recommend him enough.
                                        </h4>
                                    </div>
                                </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>      
                </div> 
                <div className='container row'>
                    <div className='col-lg-6 mucc-review'>
                        <div class="outerdiv">
                            <div class="innerdiv">
                            <div class="reviewbody eachdiv">
                                    <div class="userdetails">
                                        <div class="detbox">
                                            <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="review">
                                    <h4>
                                    The owner is skillful, responsible, and the ultimate professional. He did a wonderful job helping us with our information design challenges. I would recommend to anyone!
                                    </h4>
                                    <div className='row'>
                                            <div className='col-lg-2'>
                                                <div class="imgbox">
                                                    <img src="assets/MUCC_LOGO_una.png" alt=""/>
                                                </div>
                                            </div>
                                            <div className='col-lg-5'>
                                                <p class="name">Kathryn S.</p>
                                            </div>
                                            <div className='col-lg-5'>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <p class="designation">Midtown Utica Community Center</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 reviews-mucc'>
                        <img src="assets/reviews_mucc.jpg"/>
                    </div>
                </div>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div class="outerdiv">
                                <div class="innerdiv">
                                <div class="reviewbody eachdiv">
                                    <div class="userdetails">
                                        <div class="detbox">
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <div class="imgbox">
                                                        <img src="assets/CNY_Drone_Services_Logo_una.webp" alt=""/>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <p class="name">Rick J.</p>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="designation">CNY Drone Services</p>
                                            <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="review">
                                    <h4>
                                    We switched to this service and have been very satisfied with the results. Great design revamp and ongoing support.
                                    </h4>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="outerdiv">
                                <div class="innerdiv">
                                <div class="reviewbody eachdiv">
                                    <div class="userdetails">
                                        <div class="detbox">
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <div class="imgbox">
                                                        <img src="assets/person_icon.png" alt=""/>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <p class="name">Tiffany L.</p>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="designation">1-on-1 Online Tutoring</p>
                                            <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="review">
                                    <h4>
                                    Moreno was an excellent teacher! He's super knowledgeable about website design, and very patient. Moreno doesn’t mind going slow and walking you through the most basic components of web design! 10/10 highly recommend.
                                    </h4>
                                    </div>
                                </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="outerdiv">
                                <div class="innerdiv">
                                <div class="reviewbody eachdiv">
                                    <div class="userdetails">
                                        <div class="detbox">
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <div class="imgbox">
                                                        <img src="assets/person_icon.png" alt=""/>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <p class="name">Lauren M.</p>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <p class="designation">1-on-1 Online Tutoring</p>
                                            <i className="fa fa-quote-right fa-5x card-img-top rounded-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="review">
                                    <h4>
                                    Moreno knew every detail of squarespace inside and out. He taught me how to do the things I needed so quickly I didn’t even need the whole hour. I will seek Moreno out for sure if I ever need help again.
                                    </h4>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>      
                </div> 
            </section>
            <section id='reviews-bottom-section'>
                <div>                              
                    <h1 className='why-una'>Let's Get Started on<span> Your Site!</span></h1>
                    <br/>
                    <p> With our unique custom-code approach to web development, Una Web Solutions creates websites that 
                        stick out from the cookie-cutter sites made by website builders. Click below to get a free quote.
                    </p>
                    <div className="portfolio-main-btn py-3">
                        <a href='/contact'><button type="submit" className="main-btn-blue">Get A Free Quote</button></a>
                    </div>
                </div>
            </section>
            <div id='bottom-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
        </div>
    );
}

export default Reviews;